define('panal-fresh-admin/controllers/modals/add-product-to-order', ['exports', 'ember-sweetalert'], function (exports, _emberSweetalert) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    product: Ember.inject.service('product'),
    OrderItem: Ember.inject.service('order-item'),
    queryParams: ['query', 'enabled'],
    query: '',
    measure: '',
    quantity: Number,
    description: '',
    enabled: true,
    listProduct: Array(),
    selectedProduct: Array(),
    newAddProduct: Array(),

    initController: function (orderId = null) {
      this._resetProducSelections();
      this._loadOrderItem(this.query).then(data => {
        this.set('newAddProduct.orderId', orderId);
      });
      return this;
    },
    done: function saveCallBack(saveCallback) {
      this.set('saveCallback', saveCallback);
    },
    onQueryUpdated: Ember.observer('query', function () {
      Ember.run.debounce(this, this.applyQuery, 1000);
    }),
    onQuantityUpdate: Ember.observer('quantity', function () {
      this.set('newAddProduct.quantity', parseInt(this.quantity));
    }),
    onEnabledUpdated: Ember.observer('enabled', function () {
      this.applyQuery();
    }),

    applyQuery: function () {
      this._loadOrderItem(this.query).then(data => {
        this.set('listProduct', data);
      });
    },
    valid: function () {
      if (this.newAddProduct.orderId && this.newAddProduct.productId && this.newAddProduct.quantity && this.newAddProduct.uom_id) {
        return true;
      }
      return false;
    },
    message: "añadir Productos",
    actions: {
      submit: function () {
        if (this.valid()) {
          this._addItem();
        } else {
          return alert("no terminado");
        }
      },
      editProduct: function (product) {
        this.set('selectedProduct', product);
        this.set('newAddProduct.productId', product);
      },
      radioCheck: function (ms) {
        this.set('measure', ms.uom.uom_id);
        this.set('newAddProduct.uom_id', ms);
      }
    },
    _loadOrderItem: function (query) {

      return this.get('product').all(`filters=enabled%3A1&statistics=false&q=${query}&offset=0&limit=25`);
    },
    _resetProducSelections: function () {
      this.set('query', '');
      this.set('measure', '');
      this.set('quantity', Number);
      this.set('description', '');
      this.set('enabled', true);
      this.set('listProduct', Array());
      this.set('selectedProduct', Array());
      this.set('newAddProduct', Array());
    },
    _addItem: function () {
      let payload = {
        product_id: this.newAddProduct.productId.product_id,
        uom_id: this.newAddProduct.uom_id.uom.uom_id,
        cost: this.newAddProduct.uom_id.cost,
        description: this.get('description'),
        original_cost: this.newAddProduct.uom_id.cost,
        original_price: this.newAddProduct.uom_id.price,
        price: this.newAddProduct.uom_id.price,
        product: this.newAddProduct.productId,
        quantity: this.newAddProduct.quantity,
        total_price: this.newAddProduct.uom_id.price * this.newAddProduct.quantity,
        uom: this.newAddProduct.uom_id.uom,
        uom_display: this.newAddProduct.uom_id.uom.name,
        uom_quantity: this.newAddProduct.uom_id.uom_quantity
      };

      return this.get('OrderItem').addItem(this.newAddProduct.orderId, this.newAddProduct.productId.product_id, payload).then(() => {
        this.get('saveCallback')();
        jQuery('.modal').modal('hide');
      }).catch(error => {
        (0, _emberSweetalert.default)({
          title: "Error",
          type: "error",
          text: error.responseJSON.message,
          showConfirmButton: true
        }).then(() => {});
      });
    }
  });
});