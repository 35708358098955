define('panal-fresh-admin/controllers/modals/create-subcategory', ['exports', 'ember-sweetalert'], function (exports, _emberSweetalert) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    subcategoryService: Ember.inject.service('subcategory'),

    initController: function (subcategoryId = null, categoryId = null) {
      if (subcategoryId) {
        this._loadSubcategory(subcategoryId).then(subcategory => {
          this.set('title', 'Editar sub-categoría');
          this.set('subcategory', subcategory);
        }).catch(error => {
          (0, _emberSweetalert.default)({
            title: "Error",
            type: "error",
            text: error.responseJSON.message,
            showConfirmButton: true
          }).then(() => {
            $('.modal').modal('hide');
          });
        });
      } else {
        this.set('title', 'Crear sub-categoría');
        this.set('categoryId', categoryId);
        this.set('subcategory', {
          name: '',
          category_id: ''
        });
      }

      return this;
    },

    done: function saveCallBack(saveCallback) {
      this.set('saveCallback', saveCallback);
    },

    actions: {
      submit: function () {
        if (this.get('subsidiary.subsidiary_id') === undefined && this.get('subcategory.name') == '') {
          (0, _emberSweetalert.default)({
            title: "Error",
            type: "error",
            text: "Nombre es requerido.",
            showConfirmButton: true
          }).then(() => {});
        } else {
          this._saveSubcategory();
        }
      }
    },

    _saveSubcategory() {
      let subcategoryToSave = Object.assign({}, this.get('subcategory'));
      let promise = null;
      if (subcategoryToSave.subcategory_id) {
        promise = this.get('subcategoryService').update(subcategoryToSave.subcategory_id, { name: subcategoryToSave.name });
      } else {
        subcategoryToSave.category_id = this.get('categoryId');
        promise = this.get('subcategoryService').save(subcategoryToSave);
      }

      return promise.then(() => {
        this.get('saveCallback')();
        jQuery('.modal').modal('hide');
      }).catch(error => {
        (0, _emberSweetalert.default)({
          title: "Error",
          type: "error",
          text: error.responseJSON.message,
          showConfirmButton: true
        }).then(() => {});
      });
    },

    _loadSubcategory: function (subcategoryId) {
      return this.get('subcategoryService').findById(subcategoryId);
    }

  });
});