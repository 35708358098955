define('panal-fresh-admin/mixins/protected-route-mixin', ['exports', 'md5', 'panal-fresh-admin/config/environment'], function (exports, _md, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    permissions: ['Admin'],
    requiredRoles: function () {
      return this.get('permissions');
    },

    beforeModel: function (event) {
      let menu = _environment.default.menu.filter(page => {
        return page.targetName == event.targetName;
      });
      let updatePermissions = [];
      try {
        menu[0].roles.map(role => {
          updatePermissions.push(role.name);
        });
      } catch (error) {}
      if (updatePermissions != []) {
        this.set('permissions', updatePermissions);
      }
      if (!localStorage.getItem('userUID')) {
        return Ember.RSVP.reject({
          status: 'Unauthorized',
          message: 'Porfavor inicie sesión para acceder a esta página.'
        });
      } else {
        let roles = JSON.parse(localStorage.getItem('__r') || '[]');

        if (this.requiredRoles) {
          let hasPermission = true;
          let requiredRoles = this.requiredRoles() || [];
          try {
            requiredRoles.forEach(requiredRole => {
              let role = roles.find(role => {
                return role == (0, _md.default)(requiredRole);
              });

              if (!role) {
                hasPermission = false;
              } else {
                hasPermission = true;
                throw hasPermission;
              }
            });
          } catch (error) {}

          if (!hasPermission) {
            return Ember.RSVP.reject({
              status: 'Unauthorized',
              message: 'Usted no tiene autorización para acceder a esta página.'
            });
          }
        }
      }
    }
  });
});