define('panal-fresh-admin/controllers/modals/edit-order-item', ['exports', 'ember-sweetalert'], function (exports, _emberSweetalert) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    orderItemService: Ember.inject.service('order-item'),
    measuresSelected: null,
    allMeasures: null,

    initController: function (orderId = null, productId = null) {
      this._loadOrderItem(orderId, productId).then(orderItem => {
        this.send('getAllMeasures', orderItem);
        this.set('measures', orderItem.product.measures);
        this.set('orderId', orderId);
        this.set('orderItem', orderItem);
        this.set('title', `Editar ${orderItem.product.name}`);
      });
      return this;
    },

    done: function saveCallBack(saveCallback) {
      this.set('saveCallback', saveCallback);
    },

    quantityChanged: Ember.observer('orderItem.quantity', function () {
      if (this.get('orderItem.quantity') === '0') {
        this.set('orderItem.quantity', 1);
      }
    }),

    actions: {

      getAllMeasures: function (orderItem) {
        var self = this;
        var allMeasures = [];
        for (var i = orderItem.product.measures.length - 1; i >= 0; i--) {
          var item = orderItem.product.measures[i];
          var displayName = item.uom_quantity == "1" ? item.uom_quantity + ' ' + item.uom.name : item.uom_quantity + ' ' + item.uom.plural;
          allMeasures.push({ uom_id: item.uom.uom_id, uom_quantity: item.uom_quantity, display_name: displayName });
        };

        var measuresSelected = allMeasures.find(item => item.uom_id == orderItem.uom_id && item.uom_quantity == orderItem.uom_quantity);
        this.set('allMeasures', allMeasures);
        this.set('measuresSelected', measuresSelected);
      },

      submit: function () {
        if (this.get('orderItem.quantity') == '' || this.get('orderItem.price') == '') {
          (0, _emberSweetalert.default)({
            title: "Error",
            type: "error",
            text: "Los campos requeridos son obligatorios.",
            showConfirmButton: true
          }).then(() => {});
        } else {
          this._updateItem();
        }
      }
    },

    _updateItem: function () {
      let itemToUpdate = Object.assign({}, this.get('orderItem'));
      let newMesure = this.get('measuresSelected') ? this.get('measuresSelected') : null;
      let payload = {
        'quantity': itemToUpdate.quantity,
        'uom_quantity': newMesure ? newMesure.uom_quantity : itemToUpdate.uom_quantity,
        'uom_id': newMesure ? newMesure.uom_id : itemToUpdate.uom.uom_id,
        'price': itemToUpdate.price
      };
      return this.get('orderItemService').update(this.get('orderId'), itemToUpdate.order_item_id, payload).then(_ => {
        this.get('saveCallback')();
        jQuery('.modal').modal('hide');
      }).catch(error => {
        (0, _emberSweetalert.default)({
          title: "Error",
          type: "error",
          text: error.responseJSON.message,
          showConfirmButton: true
        }).then(() => {});
      });
    },

    _loadOrderItem: function (orderId, productId) {
      return this.get('orderItemService').findById(orderId, productId);
    }
  });
});