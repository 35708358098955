define('panal-fresh-admin/controllers/modals/print-order-invoice', ['exports', 'ember-sweetalert', 'qrcode'], function (exports, _emberSweetalert, _qrcode) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    orderService: Ember.inject.service('order'),
    panalService: Ember.inject.service('panal'),

    json: null,

    payload: null,

    initController: function (orderId) {
      this._reset();
      this._loadOrder(orderId).then(order => {
        let payload = {
          "nit": order.nit,
          "client_name": order.business_name,
          "external_order_id": order.order_id,
          "subsidiary_id": "1",
          "invoice_detail": []
        };
        this.set('order', order);
        this.set('title', `Imprimir orden: ${order.order_id} (${order.status.display_name})`);
        order.items.forEach(element => {
          let item = {
            "external_item_id": element.product.product_id,
            "product_name": element.product.name + '-' + element.uom_display,
            "unitary_price": Number(element.price),
            "quantity": element.quantity
          };
          payload.invoice_detail.push(item);
        });
        this.set('payload', payload);
        this.send('createInvoice');
        //this.send('prinInvoice');
      });
      return this;
    },

    done: function saveCallBack(saveCallback) {
      this.set('saveCallback', saveCallback);
    },

    actions: {
      submit: function () {
        $('#print-container').printThis({
          debug: false,
          printContainer: true,
          importCSS: true,
          importStyle: true,
          formValues: true,
          copyTagClasses: true,
          canvas: true

        });
      },

      createInvoice() {
        var self = this;
        let payload = this.get('payload');
        let token = localStorage.getItem('token');
        (0, _emberSweetalert.default)({
          title: "¿Estas Seguro?",
          type: "warning",
          text: "¿Estas seguro que todos los precios estan correctamente actualizados?",
          showConfirmButton: true
        }).then(() => {
          this._getTOken().then(response => {
            console.log(response);
            $.ajax({
              type: "POST",
              contentType: "application/json",
              headers: {
                'Accept': "application/json",
                'x-access-token': response.token
              },
              url: "http://panal.kumisolutions.com:3031/api/invoice/external",
              data: JSON.stringify(payload),
              success: function (data) {
                self.set('json', data);
              }
            });
          });
        });
      }

    },

    prinInvoice(panalToken) {
      var self = this;
      let payload = this.get('payload');
      const token = panalToken.token;
      console.log(token);
      // $.ajax({
      //   type: "GET",
      //   contentType: "application/json",
      //   headers: {
      //     'Accept': "application/json", 
      //     'x-access-token': token,
      // },
      //   url: "http://panal.kumisolutions.com:3031/api/invoice/277?external=true",
      //   success: function (data) {
      //     console.log(data);
      //     self.set('json', data);
      //   },
      // })
    },

    _reset: function () {
      this.set('order', null);
      this.set('title', `Loading...`);
    },

    _loadOrder: function (orderId) {
      return this.get('orderService').findById(orderId);
    },

    _getTOken: function () {
      return this.get('panalService').all();
    }
  });
});