define('panal-fresh-admin/helpers/format-decimal', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.formatDecimal = formatDecimal;
  function formatDecimal([value, ...rest]) {
    let number = Number(value);
    return Ember.String.htmlSafe(number.toFixed(2));
  }

  exports.default = Ember.Helper.helper(formatDecimal);
});