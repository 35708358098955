define('panal-fresh-admin/components/kumi-modal', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    actions: {
      ok: function () {
        //jQuery('.modal').modal('hide');
        this.sendAction('ok');
      },

      extra: function (action) {
        this.sendAction('extra', action);
      }
    },

    didInsertElement() {
      jQuery('.modal').modal().on('hidden.bs.modal', function () {
        this.sendAction('close');
      }.bind(this));
    }
  });
});