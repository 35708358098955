define('panal-fresh-admin/controllers/modals/create-category', ['exports', 'ember-sweetalert'], function (exports, _emberSweetalert) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    categoryService: Ember.inject.service('category'),

    initController: function (categoryId = null) {
      if (categoryId) {
        this._loadCategory(categoryId).then(category => {
          this.set('category', category);
          this.set('title', 'Editar categoría');
        });
      } else {
        this.set('category', {
          name: '',
          weight: ''
        });
        this.set('title', 'Crear categoría');
      }
      return this;
    },

    done: function saveCallBack(saveCallback) {
      this.set('saveCallback', saveCallback);
    },

    actions: {
      submit: function () {
        if (this.get('category.name') == '' || this.get('category.weight') == '') {
          (0, _emberSweetalert.default)({
            title: "Error",
            type: "error",
            text: "Los campos requeridos son obligatorios.",
            showConfirmButton: true
          }).then(() => {});
        } else {
          this._saveCategory();
        }
      }
    },

    _saveCategory() {
      let categoryToSave = Object.assign({}, this.get('category'));
      let promise = null;
      if (categoryToSave.category_id) {
        const categoryId = categoryToSave.category_id;
        delete categoryToSave.category_id;
        promise = this.get('categoryService').update(categoryId, categoryToSave);
      } else {
        promise = this.get('categoryService').save(categoryToSave);
      }

      return promise.then(() => {
        this.get('saveCallback')();
        jQuery('.modal').modal('hide');
      }).catch(error => {
        (0, _emberSweetalert.default)({
          title: "Error",
          type: "error",
          text: error.responseJSON.message,
          showConfirmButton: true
        }).then(() => {});
      });
    },

    _loadCategory: function (categoryId) {
      return this.get('categoryService').findById(categoryId);
    }

  });
});