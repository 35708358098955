define('panal-fresh-admin/services/recipe', ['exports', 'panal-fresh-admin/mixins/api-service-mixin'], function (exports, _apiServiceMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend(_apiServiceMixin.default, {
    init: function () {
      this._super(...arguments);
    },

    all: function (queryParams = {}) {
      return this.getRequest(`/v1/recipe`, queryParams);
    },

    findById: function (recipeId) {
      return this.getRequest(`/v1/recipe/${recipeId}`);
    },

    save: function (payload) {
      return this.post('/v1/recipe', payload);
    },

    update: function (recipeId, payload) {
      return this.patch(`/v1/recipe/${recipeId}`, payload);
    },

    remove: function (recipeId) {
      return this.delete(`/v1/recipe/${recipeId}`);
    }
  });
});