define('panal-fresh-admin/controllers/modals/create-bag', ['exports', 'ember-sweetalert'], function (exports, _emberSweetalert) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  const Product = Ember.Object.extend({
    'subTotalPrice': Ember.computed('quantity', 'price', function () {
      let quantity = this.get('quantity');
      let price = this.get('price');
      return Number.parseInt(quantity, 10) * Number.parseFloat(price);
    })
  });

  exports.default = Ember.Controller.extend({
    bagService: Ember.inject.service('bag'),
    productService: Ember.inject.service('product'),
    product: null,
    selectedProducts: [],
    totalPrice: 0,

    initController: function (bagId = null) {
      this._resetProducSelections();
      this._loadProduct().then(_ => {
        return bagId ? this._loadBag(bagId) : {
          'name': '',
          'description': '',
          'price': '',
          'enabled': false
        };
      }).then(bag => {
        this.set('bag', bag);
        if (bag.bag_id) {
          this._loadProductSelected(bag.products);
          this.set('title', 'Editar bolsa');
        } else {
          this.set('title', 'Crear bolsa');
        }
        return;
      }).catch(error => {
        (0, _emberSweetalert.default)({
          title: "Error",
          type: "error",
          text: error.responseJSON.message,
          showConfirmButton: true
        }).then(() => {
          jQuery('.modal').modal('hide');
        });
      });

      return this;
    },

    done: function saveCallBack(saveCallback) {
      this.set('saveCallback', saveCallback);
    },

    subTotalChange: Ember.observer('selectedProducts.@each.subTotalPrice', function () {
      this._calculateTotalPrice();
    }),

    actions: {
      submit: function () {
        if (this.get('bag.name') == '' || this.get('bag.price') == '') {
          (0, _emberSweetalert.default)({
            title: "Error",
            type: "error",
            text: "Los campos requeridos son obligatorios.",
            showConfirmButton: true
          }).then(() => {});
        } else {
          this._savebag();
        }
      },

      selectedProduct: function () {
        if (this.get('product') === null) {
          return;
        }
        let objProduct = Object.assign({}, this.get('product'));
        let index = this.get('selectedProducts').findIndex(product => {
          return product.product_id == objProduct.product_id;
        });
        if (index != -1) {
          return;
        }

        let product = Product.create({
          'product_id': objProduct.product_id,
          'name': objProduct.name,
          'description': objProduct.description,
          'image': objProduct.image,
          'measures': objProduct.measures,
          'measure': objProduct.measures[0],
          'uom_quantity': objProduct.measures[0].uom_quantity,
          'uom_id': objProduct.measures[0].uom.uom_id,
          'price': objProduct.measures[0].price,
          'quantity': 1
        });
        this.get('selectedProducts').pushObject(product);
      },

      selectedMeasure: function (index, selection, value) {
        let measure = Object.assign({}, this.get('selectedProducts').objectAt(index).measure);
        this.get('selectedProducts').objectAt(index).set('price', measure.price);
        this.get('selectedProducts').objectAt(index).set('uom_quantity', measure.uom_quantity);
        this.get('selectedProducts').objectAt(index).set('uom_id', measure.uom.uom_id);
      },

      removeItem: function (index) {
        this.get('selectedProducts').removeAt(index);
      }
    },

    _loadBag: function (bagId) {
      return this.get('bagService').findById(bagId);
    },

    _loadProduct: function () {
      return this.get('productService').all() //{offset: 0, limit:25}
      .then(products => {

        products.forEach(product => {
          product.displayName = product.name;
        });

        this.set('products', products);
        return products;
      });
    },

    _loadProductSelected(products) {
      products.forEach(item => {
        let measure = item.product.measures.find(element => {
          return element.uom.uom_id == item.uom_id && element.uom_quantity == item.uom_quantity;
        });
        let product = Product.create({
          'product_id': item.product_id,
          'name': item.product.name,
          'description': item.product.description,
          'image': item.product.image,
          'measures': item.product.measures,
          'measure': measure,
          'uom_quantity': item.uom_quantity,
          'uom_id': item.uom_id,
          'price': measure.price,
          'quantity': item.quantity
        });
        this.get('selectedProducts').pushObject(product);
      });
      return;
    },

    _savebag() {
      let bagToSave = Object.assign({}, this.get('bag'));
      let promise = null;
      let payload = bagToSave;
      if (bagToSave.bag_id) {
        let bagId = payload.bag_id;
        delete payload.bag_id;
        payload.items = this.get('selectedProducts').map(function (item) {
          return {
            'bag_id': item.bag_id,
            'product_id': item.product_id,
            'uom_quantity': item.uom_quantity,
            'uom_id': item.uom_id,
            'quantity': item.quantity
          };
        });
        promise = this.get('bagService').update(bagId, payload);
      } else {
        payload.items = this.get('selectedProducts').map(function (item) {
          return {
            'product_id': item.product_id,
            'uom_quantity': item.uom_quantity,
            'uom_id': item.uom_id,
            'quantity': item.quantity
          };
        });
        promise = this.get('bagService').save(payload);
      }

      return promise.then(() => {
        this.get('saveCallback')();
        jQuery('.modal').modal('hide');
      }).catch(error => {
        (0, _emberSweetalert.default)({
          title: "Error",
          type: "error",
          text: error.responseJSON.message,
          showConfirmButton: true
        }).then(() => {});
      });
    },

    _calculateTotalPrice() {
      let total = 0;
      this.get('selectedProducts').forEach(item => {
        total = total + item.subTotalPrice;
      });
      this.set('totalPrice', total.toFixed(2));
    },

    _resetProducSelections: function () {
      this.set('selectedProducts', []);
    }
  });
});