define('panal-fresh-admin/helpers/is-equal-by-path', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Helper.helper(function ([leftSide, rightSide, path]) {
    if (path) {
      return Ember.get(leftSide, path) === rightSide;
    } else {
      return leftSide === rightSide;
    }
  });
});