define('panal-fresh-admin/controllers/shopping-list/edit', ['exports', 'ember-sweetalert'], function (exports, _emberSweetalert) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    shoppingListService: Ember.inject.service('shopping-list'),

    statusList: Array({
      id: 'DESIGNING',
      display: 'En diseño'
    }, {
      id: 'ACTIVE',
      display: 'En compra'
    }, {
      id: 'PURCHASED',
      display: 'Comprado'
    }, {
      id: 'CANCELED',
      display: 'Cancelado'
    }),

    shoppingListGroupController: Ember.inject.controller('modals/shopping-list-group'),
    shoppingListItemController: Ember.inject.controller('modals/shopping-list-item'),

    actions: {
      refreshModel: function () {
        Ember.getOwner(this).lookup('route:shopping-list.edit').refresh();
      },

      openCard: function (group) {
        group.set('open', !group.get('open'));
      },

      shoppingListGroup: function (groupId) {
        var self = this;
        this.send('showModal', 'modals/shopping-list-group');
        this.get('shoppingListGroupController').initController(this.get('model').shopping_list_id, groupId).done(function () {
          self.send('refreshModel');
        });
      },

      editShoppingListItem: function (itemId) {
        var self = this;
        this.send('showModal', 'modals/shopping-list-item');
        this.get('shoppingListItemController').initController(this.get('model').groups, itemId).done(function () {
          self.send('refreshModel');
        });
      },

      updateShoppingList: function () {
        let shoppingListId = this.get('model.shopping_list_id');

        let payload = {
          status: this.get('status.id')
        };

        return this.get('shoppingListService').update(shoppingListId, payload).then(order => {
          (0, _emberSweetalert.default)({
            title: "Exito",
            type: "success",
            text: "La lista de compras fue actualizada con exito.",
            showConfirmButton: true
          }).then(() => {
            this.send('refreshModel');
          });
        }).catch(error => {
          (0, _emberSweetalert.default)({
            title: "Error",
            type: "error",
            text: error.responseJSON.message,
            showConfirmButton: true
          }).then(() => {});
        });
      }
    },

    updateGroupRowPositions() {
      let rows = [];
      let groupRow = null;

      const Group = Ember.Object.extend({
        open: false
      });

      let model = this.get('model');

      model.groups.forEach((group, index) => {
        let position = index + 1;

        var groupObject = Group.create(group);
        groupObject.progressStyle = `width: ${group.purchase_percentage}%;`;

        if (groupRow == null) {
          groupRow = {
            id: group.shopping_list_group_id,
            items: []
          };
        }

        groupRow.items.push(groupObject);

        if (position % 3 == 0) {
          rows.push(groupRow);
          groupRow = null;
        }
      });

      if (groupRow && groupRow.items.length > 0) {
        rows.push(groupRow);
      }

      this.set('rows', rows);
    }
  });
});