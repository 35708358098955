define('panal-fresh-admin/services/category', ['exports', 'panal-fresh-admin/mixins/api-service-mixin'], function (exports, _apiServiceMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend(_apiServiceMixin.default, {
    init: function () {
      this._super(...arguments);
    },

    all: function (queryParams = {}) {
      return this.getRequest(`/v1/category`, queryParams);
    },

    findById: function (categoryId) {
      return this.getRequest(`/v1/category/${categoryId}`);
    },

    save: function (payload) {
      return this.post('/v1/category', payload);
    },

    update: function (categoryId, payload) {
      return this.patch(`/v1/category/${categoryId}`, payload);
    },

    remove: function (categoryId) {
      return this.delete(`/v1/category/${categoryId}`);
    },

    subcategories: function () {
      return this.getRequest('/v1/subcategory');
    }
  });
});