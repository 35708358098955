define('panal-fresh-admin/routes/application', ['exports', 'ember-sweetalert', 'panal-fresh-admin/config/environment', 'md5'], function (exports, _emberSweetalert, _environment, _md) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    session: Ember.inject.service(),
    userService: Ember.inject.service('user'),
    menuIsOpen: false,

    beforeModel: function () {
      return this.get('session').fetch().catch(function () {});
    },

    setupController: function (controller, model, transition) {
      if (!controller.get('region')) {
        let regionOnStorage = localStorage.getItem('region');
        let savedRegion = null;
        if (regionOnStorage) {
          savedRegion = JSON.parse(regionOnStorage);
        }

        if (!savedRegion) {
          savedRegion = controller.get('regions.firstObject');
        }

        controller.set('region', savedRegion);
      }

      this._loadRoles();
      this._super(controller, model);
    },

    _loadRoles: function () {
      let userUID = localStorage.getItem('userUID');
      if (!userUID) {
        this.controller.renderMenu();
        return;
      }

      return this.get('userService').roles(userUID).then(userRoles => {
        if (userRoles.length == 0) {
          localStorage.setItem('__r', '[]');
          return null;
        }

        let userRole = userRoles[0] || null;

        if (userRole) {
          let roles = [];
          userRole.roles.forEach(role => {
            roles.push((0, _md.default)(role.name));
          });
          localStorage.setItem('__r', JSON.stringify(roles));
        }
        this.controller.renderMenu(userRole.roles);
        return userRole;
      });
    },

    actions: {
      signIn: function (provider) {
        let self = this;
        this.get('session').open('firebase', {
          provider: provider
        }).then(function (data) {
          localStorage.setItem('userUID', data.uid);
          return self._loadRoles();
        });
      },

      signOut: function () {
        this.get('session').close();
        localStorage.removeItem('userUID');
        localStorage.removeItem('__r');
        return this._loadRoles();
      },

      error: function (error, transition) {
        if (error.status === 'Unauthorized') {
          (0, _emberSweetalert.default)({
            title: "Autenticación",
            type: "error",
            text: error.message,
            showConfirmButton: true
          }).then(() => {});
          this.transitionTo('index');
        } else {
          // Allow other error to bubble
          return true;
        }
      },

      showModal: function (name, model) {
        this.render(name, {
          into: 'application',
          outlet: 'modal',
          model: model
        });
      },

      removeModal: function () {
        this.disconnectOutlet({
          outlet: 'modal',
          parentView: 'application'
        });
      },
      toggleMenu: function (val) {
        if (this.get('menuIsOpen') == true) {
          Ember.$('.sidebar').removeClass('sidebar--open');
          this.set('menuIsOpen', false);
        } else {
          this.set('menuIsOpen', true);
          Ember.$('.sidebar').addClass('sidebar--open');
        }
      }
    }
  });
});