define('panal-fresh-admin/routes/assign-order/index', ['exports', 'panal-fresh-admin/config/environment', 'panal-fresh-admin/mixins/pagination-route-mixin', 'panal-fresh-admin/mixins/protected-route-mixin'], function (exports, _environment, _paginationRouteMixin, _protectedRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_protectedRouteMixin.default, _paginationRouteMixin.default, {
    queryParams: {
      query: {
        refreshModel: true
      },
      city: {
        refreshModel: true
      }
    },

    model: function (queryParams) {
      var promises = [];

      var params = {};
      if (queryParams.query) {
        params.q = queryParams.query;
      }
      if (queryParams.city) {
        params.filters = `city:${queryParams.city}`;
      }
      queryParams.perPage = 50;
      promises.push(this.findPaged({
        type: 'GET',
        url: `${_environment.default.APP.API_HOST}/v1/order`,
        data: params,
        headers: {}
      }, queryParams));
      var shopperLimit = { perPage: 100 };
      promises.push(this.findPaged({
        type: 'GET',
        url: `${_environment.default.APP.API_HOST}/v1/user_configuration/role/2/city/${queryParams.city}`,
        headers: {}
      }, shopperLimit));

      return Promise.all(promises);
    },

    setupController: function (controller, model, transition) {
      this._super(controller, model, transition);

      let modelOrder = model[0];
      let modelUsers = model[1];

      modelOrder.forEach((item, index, arr) => {
        arr[index].title = "" + item.order_id;
      });

      controller.set('markerBlue', { url: "http://maps.google.com/mapfiles/ms/icons/blue.png", scaledSize: new google.maps.Size(50, 50) });
      controller.set('markerYellow', { url: "http://maps.google.com/mapfiles/ms/icons/yellow.png", scaledSize: new google.maps.Size(50, 50) });
      controller.set('markerRed', { url: "http://maps.google.com/mapfiles/ms/icons/red.png", scaledSize: new google.maps.Size(50, 50) });
      controller.set('markerBlueDot', { url: "http://maps.google.com/mapfiles/ms/icons/blue-dot.png", scaledSize: new google.maps.Size(50, 50) });
      controller.set('markerYellowDot', { url: "http://maps.google.com/mapfiles/ms/icons/yellow-dot.png", scaledSize: new google.maps.Size(50, 50) });
      controller.set('markerRedDot', { url: "http://maps.google.com/mapfiles/ms/icons/red-dot.png", scaledSize: new google.maps.Size(50, 50) });

      controller.set('modelOrder', modelOrder), controller.set('modelUsers', modelUsers), controller.set('latitude', modelOrder.length > 0 ? modelOrder[0].latitude : "-17.39083096");
      controller.set('longitude', modelOrder.length > 0 ? modelOrder[0].longitude : "-66.19962929");

      let cityValues = [{
        id: 'CBB',
        display_name: 'Cochabamba'
      }, {
        id: 'LPZ',
        display_name: 'La Paz'
      }, {
        id: 'EAL',
        display_name: 'El Alto'
      }, {
        id: 'SCZ',
        display_name: 'Santa Cruz'
      }];
      controller.set('cityList', cityValues);
      controller.set('citySelected', cityValues[0]);
      controller.set('errors', []);
    }
  });
});