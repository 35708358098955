define('panal-fresh-admin/routes/product/index', ['exports', 'panal-fresh-admin/config/environment', 'panal-fresh-admin/mixins/pagination-route-mixin', 'panal-fresh-admin/mixins/protected-route-mixin'], function (exports, _environment, _paginationRouteMixin, _protectedRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_protectedRouteMixin.default, _paginationRouteMixin.default, {
    queryParams: {
      query: {
        refreshModel: false
      },
      enabled: {
        refreshModel: false
      }
    },

    model: function (queryParams) {
      var url = `${_environment.default.APP.API_HOST}/v1/product`;

      var params = {
        filters: `enabled:${queryParams.enabled ? '1' : '0'}`,
        statistics: false
      };

      if (queryParams.query) {
        params.q = queryParams.query;
      }

      return this.findPaged({
        type: 'GET',
        url: url,
        data: params,
        headers: {}
      }, queryParams);
    },

    setupController: function (controller, model, transition) {
      this._super(controller, model, transition);

      controller.set('errors', []);
    }
  });
});