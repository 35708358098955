define('panal-fresh-admin/controllers/application', ['exports', 'panal-fresh-admin/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    regions: Array({
      schema: 'cochabamba',
      display: 'Cochabamba'
    }, {
      schema: 'cochabamba-restaurantes',
      display: 'Cochabamba Restaurantes'
    }, {
      schema: 'santacruz',
      display: 'Santa Cruz'
    }),
    menuByRole: Array(),

    onRegionSelected: Ember.observer('region', function () {
      localStorage.setItem('region', JSON.stringify(this.get('region')));
      let applicationRouter = Ember.getOwner(this).lookup('route:application');
      applicationRouter.refresh();
      applicationRouter._loadRoles();

      //this.transitionToRoute('index');
    }),

    renderMenu: function (role) {
      let renderMenu = Array();
      if (!role) {
        this.set('menuByRole', renderMenu);
        return;
      };

      let menu = _environment.default.menu;
      menu.map(item => {
        let roleMenu = item.roles;
        role.map(data => {
          roleMenu.map(element => {
            if (element.role_id == data.role_id) renderMenu.push(item);
          });
        });
      });
      renderMenu = renderMenu.filter((menu, index) => renderMenu.indexOf(menu) === index);
      this.set('menuByRole', renderMenu);
    }

  });
});