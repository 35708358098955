define('panal-fresh-admin/services/suggestion', ['exports', 'panal-fresh-admin/mixins/api-service-mixin'], function (exports, _apiServiceMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend(_apiServiceMixin.default, {
    init: function () {
      this._super(...arguments);
    },

    remove: function (suggestionId) {
      return this.delete(`/v1/product_suggestion/${suggestionId}`);
    }
  });
});