define('panal-fresh-admin/services/uom', ['exports', 'panal-fresh-admin/mixins/api-service-mixin'], function (exports, _apiServiceMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend(_apiServiceMixin.default, {
    init: function () {
      this._super(...arguments);
    },

    all: function () {
      return this.getRequest('/v1/uom');
    },
    findById: function (uomId) {
      return this.getRequest(`/v1/uom/${uomId}`);
    },
    save: function (payload) {
      return this.post('/v1/uom', payload);
    },

    update: function (uomId, payload) {
      return this.patch(`/v1/uom/${uomId}`, payload);
    },

    remove: function (uomId) {
      return this.delete(`/v1/uom/${uomId}`);
    }
  });
});