define('panal-fresh-admin/routes/order/index', ['exports', 'panal-fresh-admin/config/environment', 'panal-fresh-admin/mixins/pagination-route-mixin', 'panal-fresh-admin/mixins/protected-route-mixin'], function (exports, _environment, _paginationRouteMixin, _protectedRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_protectedRouteMixin.default, _paginationRouteMixin.default, {
    queryParams: {
      filter: {
        refreshModel: true
      },
      city: {
        refreshModel: true
      }
    },

    model: function (queryParams) {
      var url = `${_environment.default.APP.API_HOST}/v1/order`;

      var params = {};
      if (queryParams.filter || queryParams.city) {
        params.q = queryParams.filter;
        params.filters = `city:${queryParams.city}`;
      }
      return this.findPaged({
        type: 'GET',
        url: url,
        data: params,
        headers: {}
      }, queryParams);
    },

    setupController: function (controller, model, transition) {
      let totalOrderCost = 0;
      model.forEach(element => {
        totalOrderCost = totalOrderCost + Number(element.total_cost);
      });
      this._super(controller, model, transition);
      controller.set('total_order_cost', totalOrderCost.toFixed(2));
      controller.set('errors', []);
    }
  });
});