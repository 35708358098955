define('panal-fresh-admin/controllers/category/index', ['exports', 'panal-fresh-admin/mixins/pagination-controller-mixin'], function (exports, _paginationControllerMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend(_paginationControllerMixin.default, {
    categoryService: Ember.inject.service('category'),

    queryParams: ['query', 'enabled'],
    query: '',
    enabled: true,

    createCategoryController: Ember.inject.controller('modals/create-category'),
    viewCategoryController: Ember.inject.controller('modals/view-category-details'),

    onQueryUpdated: Ember.observer('query', function () {
      Ember.run.debounce(this, this.applyQuery, 1000);
    }),

    onEnabledUpdated: Ember.observer('enabled', function () {
      this.applyQuery();
    }),

    applyQuery: function () {
      this.send('resetPagination');
    },

    actions: {
      refreshModel: function () {
        Ember.getOwner(this).lookup('route:category').refresh();
      },

      createCategory: function () {
        var self = this;
        this.send('showModal', 'modals/create-category');
        this.get('createCategoryController').initController().done(function () {
          self.send('refreshModel');
        });
      },

      editCategory: function (categoryId) {
        var self = this;
        this.send('showModal', 'modals/create-category');
        this.get('createCategoryController').initController(categoryId).done(function () {
          self.send('refreshModel');
        });
      },

      deleteCategory: function (categoryId) {
        var self = this;

        swal({
          title: '¿Está seguro que desea eliminar la categoría?',
          text: "No podras revertir este proceso.",
          type: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Si, eliminalo!'
        }).then(result => {
          if (result) {
            self._deleteCategory(categoryId);
          }
        });
      },

      changeCategoryState: function (category) {
        // alert(categoryId);
        console.log(category);
        let payload = { 'disabled': !category.disabled };
        console.log(payload);
        this.get('categoryService').update(category.category_id, payload).then(() => {
          this.send('refreshModel');
        });

        // this.changeCategoryState(categoryId,);
      },

      viewProvider: function (categoryId) {
        var self = this;
        this.send('showModal', 'modals/view-category-details');
        this.get('viewCategoryController').initController(categoryId).done(function () {
          self.send('refreshModel');
        });
      }
    },

    _deleteCategory: function (categoryId) {
      return this.get('categoryService').remove(categoryId).then(() => {
        this.send('refreshModel');
        swal('Eliminado!', 'La categoría ha sido eliminada.', 'success');
      }).catch(error => {
        swal({
          title: "Error",
          type: "error",
          text: "La categoría no puede ser eliminada, porque tiene subcategoría relacionada.",
          showConfirmButton: true
        }).then(() => {});
      });
    },
    _changeCategoryState: function (categoryId) {
      return this.get('categoryService').update(categoryId).then(() => {
        this.send('refreshModel');
        swal('Eliminado!', 'La categoría ha sido eliminada.', 'success');
      }).catch(error => {
        swal({
          title: "Error",
          type: "error",
          text: "La categoría no puede ser eliminada, porque tiene subcategoría relacionada.",
          showConfirmButton: true
        }).then(() => {});
      });
    }
  });
});