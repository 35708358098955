define('panal-fresh-admin/controllers/modals/create-client', ['exports', 'ember-sweetalert'], function (exports, _emberSweetalert) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    clientService: Ember.inject.service('client'),

    initController: function (clientId = null) {
      if (clientId) {
        this._loadClient(clientId).then(client => {
          this.set('client', client);
          this.set('emailPrev', client.email);
          this.set('business_name', client.business_name);
          this.set('nit', client.nit);
          this.set('title', 'Editar cliente');
        });
      } else {
        this.set('client', {
          name: '',
          email: '',
          password: '',
          verifyPassword: '',
          business_name: '',
          nit: ''
        });
        this.set('title', 'Crear cliente');
      }
      return this;
    },

    done: function saveCallBack(saveCallback) {
      this.set('saveCallback', saveCallback);
    },

    actions: {
      submit: function () {
        if (this.get('client.user_uid')) {
          if (this.get('client.name') == '' || this.get('client.email') == '') {
            (0, _emberSweetalert.default)({
              title: "Error",
              type: "error",
              text: "Los campos requeridos son obligatorios.",
              showConfirmButton: true
            }).then(() => {});
          } else {
            this._saveClient();
          }
        } else {
          if (this.get('client.name') == '' || this.get('client.email') == '' || this.get('client.password') == '' || this.get('client.verifyPassword') == '') {
            (0, _emberSweetalert.default)({
              title: "Error",
              type: "error",
              text: "Los campos requeridos son obligatorios.",
              showConfirmButton: true
            }).then(() => {});
          } else if (this.get('client.password') !== this.get('client.verifyPassword')) {
            (0, _emberSweetalert.default)({
              title: "Error",
              type: "error",
              text: "Password no válido.",
              showConfirmButton: true
            }).then(() => {});
          } else if (this.get('client.password') !== this.get('client.verifyPassword')) {
            (0, _emberSweetalert.default)({
              title: "Error",
              type: "error",
              text: "Passwords diferentes, por favor verifique su password.",
              showConfirmButton: true
            }).then(() => {});
          } else {
            this._saveClient();
          }
        }
      }
    },

    _saveClient: function () {
      let clientToSave = Object.assign({}, this.get('client'));
      let promise = null;
      if (clientToSave.user_uid) {
        if (clientToSave.email === this.get('emailPrev')) {
          delete clientToSave.email;
        }
        const clientUId = clientToSave.user_uid;
        delete clientToSave.user_uid;
        delete clientToSave.verifyPassword;
        delete clientToSave.web_page;
        promise = this.get('clientService').update(clientUId, clientToSave);
      } else {
        promise = this.get('clientService').save(clientToSave);
      }

      return promise.then(() => {
        this.get('saveCallback')();
        jQuery('.modal').modal('hide');
      }).catch(error => {
        (0, _emberSweetalert.default)({
          title: "Error",
          type: "error",
          text: error.responseJSON.message,
          showConfirmButton: true
        }).then(() => {});
      });
    },

    _loadClient: function (clientId) {
      return this.get('clientService').findById(clientId);
    }

  });
});