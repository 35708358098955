define('panal-fresh-admin/mixins/pagination-controller-mixin', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    queryParams: ['page', 'perPage'],
    page: 1,
    perPage: 25,
    totalPages: 1,
    pagination: null,

    updatePagination: function () {
      var self = this;

      const Page = Ember.Object.extend({
        value: null,
        isSelected: Ember.computed('value', 'self.page', function () {
          return this.get('value') === self.get('page');
        })
      });

      var paginationList = [];
      for (var index = 1; index <= this.get('totalPages'); index++) {
        paginationList.push(Page.create({
          value: index
        }));
      }

      this.set('pagination', paginationList);
    },

    isPageSelected: Ember.computed('page', function (currentPage) {
      return currentPage === this.get('page') ? 'disable' : 'active';
    }),

    actions: {
      pageTo: function (page) {
        this.set('page', page);
      },

      previousPage: function () {
        if (this.get('page') > 1) {
          this.decrementProperty('page');
        }
      },

      nextPage: function () {
        if (this.get('page') < this.get('totalPages')) {
          this.incrementProperty('page');
        }
      },

      resetPagination: function () {
        this.set('page', 1);
        let currentPath = Ember.getOwner(this).lookup('controller:application').currentPath;
        Ember.getOwner(this).lookup(`route:${currentPath}`).refresh();
      }
    }
  });
});