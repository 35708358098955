define('panal-fresh-admin/services/step', ['exports', 'panal-fresh-admin/mixins/api-service-mixin'], function (exports, _apiServiceMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend(_apiServiceMixin.default, {
    init: function () {
      this._super(...arguments);
    },

    all: function (queryParams = {}) {
      return this.getRequest(`/v1/step`, queryParams);
    },

    findById: function (recipeId, stepId) {
      return this.getRequest(`/v1/recipe/${recipeId}/step/${stepId}`);
    },

    save: function (payload) {
      return this.post('/v1/step', payload);
    },

    update: function (recipeId, stepId, payload) {
      return this.patch(`/v1/recipe/${recipeId}/step/${stepId}`, payload);
    },

    remove: function (stepId) {
      return this.delete(`/v1/step/${stepId}`);
    }

  });
});