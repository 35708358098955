define('panal-fresh-admin/routes/measure', ['exports', 'panal-fresh-admin/config/environment', 'panal-fresh-admin/mixins/pagination-route-mixin', 'panal-fresh-admin/mixins/protected-route-mixin'], function (exports, _environment, _paginationRouteMixin, _protectedRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_protectedRouteMixin.default, _paginationRouteMixin.default, {

    model: function (queryParams) {
      var url = `${_environment.default.APP.API_HOST}/v1/uom`;

      return this.findPaged({
        type: 'GET',
        url: url,
        headers: {}
      }, queryParams);
    },

    setupController: function (controller, model, transition) {
      this._super(controller, model);

      controller.set('errors', []);
    }
  });
});