define('panal-fresh-admin/controllers/provider/index', ['exports', 'panal-fresh-admin/mixins/pagination-controller-mixin'], function (exports, _paginationControllerMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend(_paginationControllerMixin.default, {
    providerService: Ember.inject.service('provider'),

    queryParams: ['query', 'enabled'],
    query: '',
    enabled: true,

    createProviderController: Ember.inject.controller('modals/create-provider'),
    viewProviderController: Ember.inject.controller('modals/view-provider-details'),

    onQueryUpdated: Ember.observer('query', function () {
      Ember.run.debounce(this, this.applyQuery, 1000);
    }),

    onEnabledUpdated: Ember.observer('enabled', function () {
      this.applyQuery();
    }),

    applyQuery: function () {
      this.send('resetPagination');
    },

    actions: {
      refreshModel: function () {
        Ember.getOwner(this).lookup('route:provider.index').refresh();
      },

      createProvider: function () {
        var self = this;
        this.send('showModal', 'modals/create-provider');
        this.get('createProviderController').initController().done(function () {
          self.send('refreshModel');
        });
      },

      editProvider: function (providerId) {
        var self = this;
        this.send('showModal', 'modals/create-provider');
        this.get('createProviderController').initController(providerId).done(function () {
          self.send('refreshModel');
        });
      },

      viewProvider: function (providerId) {
        var self = this;
        let val;
        this.send('showModal', 'modals/view-provider-details');
        this.get('viewProviderController').initController(providerId, val).done(function () {
          self.send('refreshModel');
        });
      },

      changeBackgroundUrl: function (providerId) {
        var self = this;
        let val = 'background_url';
        this.send('showModal', 'modals/view-provider-details');
        this.get('viewProviderController').initController(providerId, val).done(function () {
          self.send('refreshModel');
        });
      },

      deleteProvider: function (providerId) {
        var self = this;

        swal({
          title: '¿Está seguro que desea eliminar el proveedor?',
          text: "No podras revertir este proceso.",
          type: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Si, eliminalo!'
        }).then(result => {
          if (result) {
            self._deleteProvider(providerId);
          }
        });
      }
    },

    _deleteProvider: function (providerId) {
      return this.get('providerService').remove(providerId).then(() => {
        this.send('refreshModel');
        swal('Eliminado!', 'El proveedor ha sido eliminado.', 'success');
      }).catch(error => {
        swal({
          title: "Error",
          type: "error",
          text: "El proveedor no puede ser eliminado, porque tiene productos relacionados.",
          showConfirmButton: true
        }).then(() => {});
      });
    }
  });
});