define('panal-fresh-admin/components/kumi-pagination', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    actions: {
      previousPage: function () {
        this.sendAction('previousPage');
      },

      nextPage: function () {
        this.sendAction('nextPage');
      },

      pageTo: function (page) {
        this.sendAction('pageTo', page);
      }
    }
  });
});