define('panal-fresh-admin/controllers/modals/print-order', ['exports', 'ember-sweetalert'], function (exports, _emberSweetalert) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    orderService: Ember.inject.service('order'),

    initController: function (orderId) {
      this._reset();
      this._loadOrder(orderId).then(order => {
        order.businessName = order.user ? order.user.displayName : null;
        this.set('order', order);
        this.set('title', `Imprimir orden: ${order.order_id} (${order.status.display_name})`);
      });
      return this;
    },

    done: function saveCallBack(saveCallback) {
      this.set('saveCallback', saveCallback);
    },

    actions: {
      submit: function () {
        $('#print-container').printThis({
          debug: false,
          printContainer: true,
          importCSS: true,
          importStyle: true,
          formValues: true,
          copyTagClasses: true
        });
      }
    },

    _reset: function () {
      this.set('order', null);
      this.set('title', `Loading...`);
    },

    _loadOrder: function (orderId) {
      return this.get('orderService').findById(orderId);
    }
  });
});