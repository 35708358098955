define('panal-fresh-admin/controllers/category/subcategory', ['exports', 'panal-fresh-admin/mixins/pagination-controller-mixin'], function (exports, _paginationControllerMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend(_paginationControllerMixin.default, {
    subcategoryService: Ember.inject.service('subcategory'),

    queryParams: ['query', 'enabled'],
    query: '',
    enabled: true,

    createSubcategoryController: Ember.inject.controller('modals/create-subcategory'),

    onQueryUpdated: Ember.observer('query', function () {
      Ember.run.debounce(this, this.applyQuery, 1000);
    }),

    onEnabledUpdated: Ember.observer('enabled', function () {
      this.applyQuery();
    }),

    applyQuery: function () {
      this.send('resetPagination');
    },

    actions: {
      refreshModel: function () {
        Ember.getOwner(this).lookup('route:category.subcategory').refresh();
      },

      createSubcategory: function () {

        var self = this;
        this.send('showModal', 'modals/create-subcategory');
        this.get('createSubcategoryController').initController(null, this.get('categoryId')).done(function () {
          self.send('refreshModel');
        });
      },

      editSubcategory: function (subcategoryId) {
        var self = this;
        this.send('showModal', 'modals/create-subcategory');
        this.get('createSubcategoryController').initController(subcategoryId).done(function () {
          self.send('refreshModel');
        });
      },

      deleteSubcategory: function (subcategoryId) {
        var self = this;

        swal({
          title: '¿Está seguro que desea eliminar la sub-categoría?',
          text: "No podras revertir este proceso.",
          type: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Si, eliminalo!'
        }).then(result => {
          if (result) {
            self._deleteSubcategory(subcategoryId);
          }
        });
      }
    },

    _deleteSubcategory: function (subcategoryId) {
      return this.get('subcategoryService').remove(subcategoryId).then(() => {
        this.send('refreshModel');
        swal('Eliminado!', 'La sub-categoría ha sido eliminada.', 'success');
      }).catch(error => {
        swal({
          title: "Error",
          type: "error",
          text: "La sub-categoría no puede ser eliminada.",
          showConfirmButton: true
        }).then(() => {});
      });
    }
  });
});