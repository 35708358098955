define('panal-fresh-admin/controllers/modals/shopping-list-group', ['exports', 'ember-sweetalert'], function (exports, _emberSweetalert) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    productService: Ember.inject.service('product'),
    shoppingListService: Ember.inject.service('shopping-list'),

    products: Array(),
    uoms: Array(),
    extraButtons: Array(),

    onProductSelected: Ember.observer('product', function () {
      let measures = this.get('product.measures');
      let uoms = [];

      if (measures) {
        measures.forEach(measure => {
          uoms.push({
            uom_id: measure.uom.uom_id,
            uom_quantity: measure.uom_quantity,
            display: measure.uom_display
          });
        });
      }

      this.set('uoms', uoms);

      let selectedUOM = null;
      if (this.get('group.uom')) {
        selectedUOM = uoms.find(uom => {
          return uom.uom_id == this.get('group.uom.uom_id');
        });
      }

      if (selectedUOM == undefined || selectedUOM == null) {
        selectedUOM = this.get('uoms')[0] || null;
      }

      this.set('UOM', selectedUOM);
    }),

    initController: function (shoppingListId, groupId = null) {
      let groupPromise = groupId ? this._loadGroup(groupId) : {
        shopping_list_id: shoppingListId,
        unified: false,
        quantity: 1,
        items: []
      };

      Promise.resolve(groupPromise).then(group => {
        this.set('group', group);

        if (group.shopping_list_group_id) {
          this.set('products', [group.product]);
          this.set('product', group.product);

          this.set('title', 'Editar Grupo');

          this.set('extraButtons', [{
            action: 'removeGroup',
            name: 'Remover',
            class: 'btn btn-danger'
          }]);
        } else {
          this._searchProducts();
          this.set('title', 'Crear Grupo');
          this.set('extraButtons', []);
        }
      });

      return this;
    },

    done: function saveCallBack(saveCallback) {
      this.set('saveCallback', saveCallback);
    },

    actions: {
      submit: function () {
        let group = this.get('group');
        let product = this.get('product');
        let groupToSave = {
          shopping_list_id: group.shopping_list_id,
          product_id: product.product_id,
          unified: group.unified,
          uom_id: null,
          uom_quantity: null
        };

        if (group.unified) {
          let uom = this.get('UOM');
          groupToSave.uom_id = uom.uom_id;
          groupToSave.uom_quantity = uom.uom_quantity;
          groupToSave.quantity = group.quantity;
        }

        let promise;

        if (group.shopping_list_group_id) {
          promise = this.get('shoppingListService').updateGroup(group.shopping_list_group_id, groupToSave);
        } else {
          promise = this.get('shoppingListService').saveGroup(groupToSave);
        }

        return promise.then(() => {
          this.get('saveCallback')();
          jQuery('.modal').modal('hide');
        }).catch(error => {
          (0, _emberSweetalert.default)({
            title: "Error",
            type: "error",
            text: error.responseJSON.message,
            showConfirmButton: true
          }).then(() => {});
        });
      },

      extra: function (action) {
        if (action == 'removeGroup') {
          (0, _emberSweetalert.default)({
            title: '¿Está seguro que desea eliminar el grupo?',
            text: "No podras revertir este proceso.",
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Si, eliminalo!'
          }).then(result => {
            if (result) {
              this._removeGroup();
            }
          });
        }
      },

      searchProducts: function (query) {
        this._searchProducts(query);
      }
    },

    _removeGroup() {
      let group = this.get('group');

      return this.get('shoppingListService').removeGroup(group.shopping_list_group_id).then(() => {
        this.get('saveCallback')();
        jQuery('.modal').modal('hide');
        (0, _emberSweetalert.default)('Eliminado!', 'El grupo ha sido eliminado.', 'success');
      }).catch(error => {
        (0, _emberSweetalert.default)({
          title: "Error",
          type: "error",
          text: "El grupo no puede ser eliminado. Primero quite los items asignados al grupo.",
          showConfirmButton: true
        }).then(() => {});
      });
    },

    _loadGroup: function (groupId) {
      return this.get('shoppingListService').groupById(groupId);
    },

    _searchProducts: function (query = '') {
      let params = {
        statistics: false,
        offset: 0,
        limit: 25
      };

      if (query && query.length > 0) {
        params.q = query;
      }

      return this.get('productService').all(params).then(products => {

        this.set('products', products);

        let product = this.get('product');
        if (product) {
          product = products.find(item => {
            return product.product_id == item.product_id;
          });
        }

        if (!product) {
          product = products[0] || null;
        }

        this.set('product', product);

        return products;
      });
    }
  });
});