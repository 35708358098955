define('panal-fresh-admin/services/report', ['exports', 'panal-fresh-admin/mixins/api-service-mixin'], function (exports, _apiServiceMixin) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Service.extend(_apiServiceMixin.default, {
		init: function () {
			this._super(...arguments);
		},

		report: function (payload) {
			return this.post('/v1/tinkiy/top', payload);
		}

	});
});