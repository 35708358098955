define('panal-fresh-admin/controllers/order/detail', ['exports', 'ember-sweetalert'], function (exports, _emberSweetalert) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    orderService: Ember.inject.service('order'),
    orderItemService: Ember.inject.service('order-item'),
    isEditable: false,

    printOrderInvoiceController: Ember.inject.controller('modals/print-order-invoice'),
    editOrderItemController: Ember.inject.controller('modals/edit-order-item'),
    editOrderBagController: Ember.inject.controller('modals/edit-order-bag'),
    viewProvidersController: Ember.inject.controller('modals/view-providers'),
    addProductOrderController: Ember.inject.controller('modals/add-product-to-order'),

    filterCity: Array({
      id: "CBB",
      display_name: "Cochabamba"
    }, {
      id: "SCZ",
      display_name: "Santa Cruz"
    }, {
      id: "LPZ",
      display_name: "La Paz"
    }, {
      id: "EAL",
      display_name: "El Alto"
    }),
    citySelect: {
      id: "CBB",
      display_name: "Cochabamba"
    },

    actions: {
      handleDrag: function (lat, lng) {
        this.set('model.latitude', lat);
        this.set('model.longitude', lng);
      },
      updateLocationDelivery() {
        let payload = {
          latitude: this.get('model.latitude'),
          longitude: this.get('model.longitude'),
          city: this.get('citySelect.id')
        };
        this.orderService.updateLocation(this.get('model.order_id'), payload).then(() => {
          (0, _emberSweetalert.default)({
            title: "Exito",
            type: "success",
            text: "Actualizado",
            showConfirmButton: true
          });
        });
      },
      printInvoice: function () {
        var self = this;
        var orderId = this.get('model').order_id;
        this.send('showModal', 'modals/print-order-invoice');
        this.get('printOrderInvoiceController').initController(orderId).done(function () {
          self.send('refreshModel');
        });
      },

      refreshModel: function () {
        Ember.getOwner(this).lookup('route:order.detail').refresh();
      },

      updateOrder: function () {
        if (!this._validateNit()) {
          return;
        }

        let orderId = this.get('model.order_id');

        let payload = {
          status: this.get('status.id')
        };

        if (this.get('model.business_name') == null || this.get('model.business_name') == '') {
          payload['business_name'] = 'Sin nombre';
        } else {
          payload['business_name'] = this.get('model.business_name').trim();
        }

        if (this.get('model.nit') == null || this.get('model.nit') == '') {
          payload['nit'] = 0;
        } else {
          payload['nit'] = this.get('model.nit').trim();
        }

        if (payload.status == 'DELIVERED') {
          payload.delivered_at = new Date();
        } else if (payload.status == 'ACTIVE' || payload.status == 'CONFIRMED') {
          payload.delivered_at = null;
        }

        return this.get('orderService').update(orderId, payload).then(() => {
          (0, _emberSweetalert.default)({
            title: "Exito",
            type: "success",
            text: "La orden fue actualizada con exito.",
            showConfirmButton: true
          }).then(() => {
            Ember.getOwner(this).lookup('route:order.detail').refresh();
          });
        }).catch(error => {
          (0, _emberSweetalert.default)({
            title: "Error",
            type: "error",
            text: error.responseJSON.message,
            showConfirmButton: true
          }).then(() => {});
        });
      },

      addProduct: function () {
        let orderId = this.get('model.order_id');
        let status = this.get('status.id');
        var self = this;
        // alert(`añadir productos a ${orderId} con ${status}`);
        this.send('showModal', 'modals/add-product-to-order');
        this.get('addProductOrderController').initController(orderId).done(function () {
          self.send('refreshModel');
        });
      },

      editOrderItem: function (orderItemId, productId) {
        var self = this;
        this.send('showModal', 'modals/edit-order-item');
        this.get('editOrderItemController').initController(orderItemId, productId).done(function () {
          self.send('refreshModel');
        });
      },

      editOrderBag: function (orderId, bagId) {
        var self = this;
        this.send('showModal', 'modals/edit-order-bag');
        this.get('editOrderBagController').initController(orderId, bagId).done(function () {
          self.send('refreshModel');
        });
      },

      deleteOrderItem: function (orderItemId, productId) {
        var self = this;

        (0, _emberSweetalert.default)({
          title: "Cuidado",
          type: "warning",
          text: "¿Estas seguro de que quieres eliminar este item?",
          showConfirmButton: true
        }).then(() => {
          //self.get('orderItemService').remove(orderItemId, productId)
          //self.send('refreshModel');
          self._deleteItem(orderItemId, productId);
        });
      },

      saveDelivery: function () {
        let deliveryCost = this.get('model.delivery_cost') == '' ? 0 : this.get('model.delivery_cost');
        let ivaCost = this.get('model.iva_cost') == '' ? 0 : this.get('model.iva_cost');
        let orderId = this.get('model.order_id');
        return this.get('orderService').update(orderId, { 'delivery_cost': Number(deliveryCost), 'iva_cost': Number(ivaCost) }).then(() => {
          (0, _emberSweetalert.default)({
            title: "Exito",
            type: "success",
            text: "Costos actualizados.",
            showConfirmButton: true
          }).then(() => {
            this.set('isEditable', false);
            this.set('previousDeliveryCost', this.get('model.delivery_cost'));
            this.set('previousIvaCost', this.get('model.iva_cost'));
            Ember.getOwner(this).lookup('route:order.detail').refresh();
          });
        }).catch(error => {
          (0, _emberSweetalert.default)({
            title: "Error",
            type: "error",
            text: error.responseJSON.message,
            showConfirmButton: true
          }).then(() => {});
        });
      },

      changeStatusDelivery: function () {
        this.set('isEditable', true);
      },

      viewProviders: function (providers) {
        var self = this;
        this.send('showModal', 'modals/view-providers');
        this.get('viewProvidersController').initController(providers).done(function () {
          self.send('refreshModel');
        });
      },

      updateInvoiceData: function () {
        console.log('test');
        console.log(this.get('model.nit'));

        const orderId = this.get('model.order_id');
        const payload = {
          business_name: this.get('model.business_name').trim() ? this.get('model.business_name').trim() : 'Sin nombre',
          nit: this.get('model.nit') ? this.get('model.nit') : 0
        };
        this.get('orderService').update(orderId, payload).then(() => {}).catch(error => {
          (0, _emberSweetalert.default)({
            title: "Error",
            type: "error",
            text: error.responseJSON.message,
            showConfirmButton: true
          }).then(() => {});
        });
      }
    },

    _reset: function () {
      this.set('status', null);
      this.set('title', `Loading...`);
    },

    _deleteItem: function (orderItemId, productId) {
      let self = this;
      this.get('orderItemService').remove(orderItemId, productId);
      (0, _emberSweetalert.default)({
        title: "¡Lo lograste! ",
        type: "success",
        text: 'Eliminaste el archivo',
        showConfirmButton: true
      }).then(() => {
        self.send('refreshModel');
      });
    },

    _loadStatus: function (order) {
      let active = {
        id: 'ACTIVE',
        display_name: 'Activo'
      };
      let confirmed = {
        id: 'CONFIRMED',
        display_name: 'Confirmado'
      };
      let delivered = {
        id: 'DELIVERED',
        display_name: 'Entregados y pagados'
      };
      let canceled = {
        id: 'CANCELED',
        display_name: 'Cancelado'
      };
      let archived = {
        id: 'ARCHIVED',
        display_name: 'Archivado'
      };
      let waitingConfirmation = {
        id: 'WAITING_CONFIRMATION',
        display_name: 'Entregados y en espera de pago'
      };

      let statusList = [];

      if (order.status.id == 'ACTIVE') {
        statusList = [active, confirmed, canceled];
      } else if (order.status.id == 'CONFIRMED') {
        statusList = [active, confirmed, waitingConfirmation, delivered, canceled];
      } else if (order.status.id == 'DELIVERED') {
        statusList = [waitingConfirmation, delivered, archived];
      } else if (order.status.id == 'CANCELED') {
        statusList = [active, canceled];
      } else if (order.status.id == 'ARCHIVED') {
        statusList = [waitingConfirmation, delivered, archived];
      } else if (order.status.id == 'WAITING_CONFIRMATION') {
        statusList = [waitingConfirmation, active, delivered];
      }

      this.set('statusList', statusList);
      this.set('citySelect', order.city);
    },

    _loadOrder: function (orderId) {
      return this.get('orderService').findById(orderId);
    },

    _validateNit: function () {
      if (this.get('model.nit') != null && this.get('model.nit') != '') {
        if (this.get('model.business_name') == null || this.get('model.business_name') == '') {
          (0, _emberSweetalert.default)({
            title: "Error",
            type: "error",
            text: "Ingrese nombre para la Factura.",
            showConfirmButton: true
          }).then(() => {});
          return false;
        }
      }

      return true;
    }
  });
});