define('panal-fresh-admin/controllers/modals/create-recipe', ['exports', 'ember-sweetalert'], function (exports, _emberSweetalert) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  const Product = Ember.Object.extend({
    'subTotalPrice': Ember.computed('quantity', 'price', function () {
      let quantity = this.get('quantity');
      let price = this.get('price');
      return Number.parseInt(quantity, 10) * Number.parseFloat(price);
    })
  });

  exports.default = Ember.Controller.extend({
    recipeService: Ember.inject.service('recipe'),
    productService: Ember.inject.service('product'),
    utensilService: Ember.inject.service('utensil'),
    product: null,
    selectedProducts: [],
    totalPrice: 0,

    steps: Array(),
    recipeUtensils: Array(),
    extras: Array(),

    initController: function (recipeId = null) {
      this._resetProducSelections();
      this._resetForm();
      this._loadUtensils().then(utensils => {
        this.set('utensils', utensils);
      });

      this._loadProduct().then(_ => {
        return recipeId ? this._loadRecipe(recipeId) : {
          'name': '',
          'description': '',
          'url_video': '',
          'enabled': false
        };
      }).then(recipe => {
        this.set('recipe', recipe);

        if (recipe.recipe_id) {
          this._breakdownRecipe();
          this.set('title', 'Editar receta');
        } else {
          this.set('title', 'Crear receta');
          this.set('configuration', {
            'people_number': 1,
            'cooking_time': 10,
            'suggestion': '',
            'price': '0.00'
          });
        }
        return;
      }).catch(error => {
        (0, _emberSweetalert.default)({
          title: "Error",
          type: "error",
          text: error.responseJSON.message,
          showConfirmButton: true
        }).then(() => {
          jQuery('.modal').modal('hide');
        });
      });

      return this;
    },

    done: function saveCallBack(saveCallback) {
      this.set('saveCallback', saveCallback);
    },

    subTotalChange: Ember.observer('selectedProducts.@each.subTotalPrice', function () {
      this._calculateTotalPrice();
    }),

    actions: {
      addStep: function () {
        if (this.get('stepDescription') == null || this.get('stepDescription') == '') {
          this._handleError('info', 'Ingrese el paso para la receta');
          return;
        } else {
          this.get('steps').pushObject({
            'description': this.get('stepDescription')
          });
          this.set('stepDescription', '');
        }
      },

      addUtensil: function () {
        let indexUtensil = this.get('recipeUtensils').findIndex(utensil => {
          return utensil.utensil_id == this.get('utensil.utensil_id');
        });
        if (indexUtensil == -1) {
          this.get('recipeUtensils').pushObject(this.get('utensil'));
        }
      },

      addExtra: function () {
        if (this.get('nameExtra') == null || this.get('nameExtra') == '') {
          this._handleError('info', 'Ingrese el elemento no incluido en la receta');
          return;
        } else {
          this.get('extras').pushObject({
            'name': this.get('nameExtra')
          });
          this.set('nameExtra', '');
        }
      },

      removeStep: function (step) {
        this.get('steps').removeObject(step);
      },

      removeUtensil: function (utensil) {
        this.get('recipeUtensils').removeObject(utensil);
      },

      removeExtra: function (extra) {
        this.get('extras').removeObject(extra);
      },

      submit: function () {
        if (this.get('recipe.name') == null || this.get('recipe.name') == '' || this.get('recipe.description') == null || this.get('recipe.description') == '' || this.get('configuration.price') == null || this.get('configuration.price') == '' || this.get('configuration.price') < 0.00 || this.get('configuration.people_number') == null || this.get('configuration.people_number') == '' || this.get('configuration.people_number') < 1 || this.get('configuration.cooking_time') == null || this.get('configuration.cooking_time') == '' || this.get('configuration.cooking_time') < 0 || this.get('steps').length == 0) {
          this._handleError();
        } else {
          this._saveRecipe();
        }
      },

      selectedProduct: function () {
        if (this.get('product') === null) {
          return;
        }
        let objProduct = Object.assign({}, this.get('product'));
        let index = this.get('selectedProducts').findIndex(product => {
          return product.product_id == objProduct.product_id;
        });
        if (index != -1) {
          return;
        }

        let product = Product.create({
          'product_id': objProduct.product_id,
          'name': objProduct.name,
          'description': objProduct.description,
          'image': objProduct.image,
          'measures': objProduct.measures,
          'measure': objProduct.measures[0],
          'uom_quantity': objProduct.measures[0].uom_quantity,
          'uom_id': objProduct.measures[0].uom.uom_id,
          'price': objProduct.measures[0].price,
          'quantity': 1
        });
        this.get('selectedProducts').pushObject(product);
      },

      selectedMeasure: function (index, selection, value) {
        let measure = Object.assign({}, this.get('selectedProducts').objectAt(index).measure);
        this.get('selectedProducts').objectAt(index).set('price', measure.price);
        this.get('selectedProducts').objectAt(index).set('uom_quantity', measure.uom_quantity);
        this.get('selectedProducts').objectAt(index).set('uom_id', measure.uom.uom_id);
      },

      removeItem: function (index) {
        this.get('selectedProducts').removeAt(index);
      }
    },

    _loadRecipe: function (recipeId) {
      return this.get('recipeService').findById(recipeId);
    },

    _loadProduct: function () {
      return this.get('productService').all() //{offset: 0, limit:25}
      .then(products => {

        products.forEach(product => {
          product.displayName = product.name;
        });

        this.set('products', products);
        return products;
      });
    },

    _loadProductSelected(products) {
      products.forEach(item => {
        let measure = item.product.measures.find(element => {
          return element.uom.uom_id == item.uom_id && element.uom_quantity == item.uom_quantity;
        });
        let product = Product.create({
          'product_id': item.product.product_id,
          'name': item.product.name,
          'description': item.product.description,
          'image': item.product.image,
          'measures': item.product.measures,
          'measure': measure,
          'uom_quantity': item.uom_quantity,
          'uom_id': item.uom_id,
          'price': measure.price,
          'quantity': item.quantity
        });
        this.get('selectedProducts').pushObject(product);
      });
      return;
    },

    _loadUtensils: function () {
      return this.get('utensilService').all().then(utensils => {
        this.set('utensils', utensils);
        this.set('utensil', this.get('utensils')[0] || null);

        return utensils;
      });
    },

    _saveRecipe: function () {
      let arrExtras = [];

      this.get('extras').forEach(extra => {
        arrExtras.push(Object.assign({}, extra));
      });

      let arrSteps = [];

      this.get('steps').forEach((step, index) => {
        arrSteps.push(Object.assign({}, {
          step: index + 1,
          'description': step.description
        }));
      });

      let arrRecipeUtinsils = [];

      this.get('recipeUtensils').forEach(utensil => {
        arrRecipeUtinsils.push(Object.assign({}, utensil));
      });

      let arrIngredients = [];

      this.get('selectedProducts').forEach(product => {
        let item = Object.assign({}, product);

        arrIngredients.push({
          'product_id': item.product_id,
          'uom_quantity': item.uom_quantity,
          'uom_id': item.uom_id,
          'quantity': item.quantity,
          'display_name': item.name,
          'image': item.image
        });
      });

      let arrConfigurations = [];
      arrConfigurations.push(Object.assign({}, this.get('configuration')));

      let recipeToSave = Object.assign({}, this.get('recipe'));
      recipeToSave.configurations = arrConfigurations;
      recipeToSave.configurations[0].extras = arrExtras;
      recipeToSave.configurations[0].ingredients = arrIngredients;
      recipeToSave.steps = arrSteps;
      recipeToSave.utensils = arrRecipeUtinsils;

      console.log(recipeToSave);

      let promise = null;

      if (recipeToSave.recipe_id) {
        promise = this.get('recipeService').update(recipeToSave.recipe_id, recipeToSave);
      } else {
        promise = this.get('recipeService').save(recipeToSave);
      }

      return promise.then(() => {
        this.get('saveCallback')();
        jQuery('.modal').modal('hide');
      }).catch(error => {
        (0, _emberSweetalert.default)({
          title: "Error",
          type: "error",
          text: error.responseJSON.message,
          showConfirmButton: true
        }).then(() => {});
      });
    },

    _breakdownRecipe: function () {
      this.get('recipe').configurations.forEach(configuration => {
        this.set('configuration', {
          'cooking_time': configuration.cooking_time,
          'people_number': configuration.people_number,
          'price': configuration.price,
          'recipe_configuration_id': configuration.recipe_configuration_id,
          'recipe_id': configuration.recipe_id,
          'suggestion': configuration.suggestion
        });
        this._loadProductSelected(configuration.ingredients);
        configuration.extras.forEach(extra => {
          this.get('extras').pushObject(extra);
        });
      });

      this.get('recipe').steps.forEach(step => {
        this.get('steps').pushObject(step);
      });

      this.get('recipe').utensils.forEach(utensil => {
        this.get('recipeUtensils').pushObject(utensil);
      });
    },

    _handleError: function (type = 'error', message = 'Los campos requeridos son obligatorios.') {
      (0, _emberSweetalert.default)({
        title: "Error",
        type: type,
        text: message,
        showConfirmButton: true
      }).then(() => {});
    },

    _calculateTotalPrice: function () {
      let total = 0;
      this.get('selectedProducts').forEach(item => {
        total = total + item.subTotalPrice;
      });
      this.set('totalPrice', total.toFixed(2));
    },

    _resetProducSelections: function () {
      this.set('selectedProducts', []);
    },

    _resetForm: function () {
      this.set('recipeUtensils', []);
      this.set('extras', []);
      this.set('steps', []);
    }

  });
});