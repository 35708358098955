define('panal-fresh-admin/controllers/modals/create-product', ['exports', 'ember-sweetalert', 'panal-fresh-admin/utils/measure-util'], function (exports, _emberSweetalert, _measureUtil) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    productService: Ember.inject.service('product'),
    categoryService: Ember.inject.service('category'),
    uomService: Ember.inject.service('uom'),
    providerService: Ember.inject.service('provider'),

    subcategory: null,
    provider: null,
    synonyms: Array(),
    tags: Array(),
    propertyNames: Array({
      id: 'TITLE',
      displayName: 'Titulo'
    }, {
      id: 'COLOR',
      displayName: 'Color de fondo del titulo'
    }),
    properties: Array(),
    productProviders: Array(),

    initController: function (productId = null) {
      Promise.all([this._loadSubcategories(), this._loadUOMs(), this._loadProviders()]).then((subcategories, uoms, providers) => {
        return productId ? this._loadProduct(productId) : {
          has_promotion: false,
          enabled: true,
          measures: [],
          properties: []
        };
      }).then(product => {
        this.set('product', product);
        this.set('propertyNameSelected', this.get('propertyNames')[0] || null);
        if (product.product_id) {
          let subcategory = this.get('subcategories').find(item => {
            return item.subcategory_id == product.subcategory_id;
          });

          let defaultUOM = this.get('uoms').find(item => {
            return item.uom_id == product.default_uom_id;
          });
          let tags = product.tags ? product.tags.replace(/"/g, '').split(',') : [];
          let synonyms = product.synonyms ? product.synonyms.replace(/"/g, '').split(',') : [];

          let uniqueTags = tags.filter(function (item, pos) {
            return tags.indexOf(item) == pos;
          });

          let uniqueSynonyms = synonyms.filter(function (item, pos) {
            return synonyms.indexOf(item) == pos;
          });

          this.set('title', 'Editar Producto');
          this.set('subcategory', subcategory);
          this.set('tags', uniqueTags);
          this.set('synonyms', uniqueSynonyms);

          let measures = [];
          let measuresInit = [];

          product.measures.forEach(measure => {
            let item = Object.assign({}, measure);
            let uom = this.get('uoms').find(i => {
              return i.uom_id == item.uom.uom_id;
            });
            if (uom) {
              if (!defaultUOM) {
                defaultUOM = uom;
              }
              item.uom = uom;
              item.priceIva = (item.price * 1.14942528735632185).toFixed(2);
              measures.push(item);
              let itemInit = Object.assign({}, measure);
              delete itemInit.uom;
              itemInit.uom_id = uom.uom_id;
              if (!itemInit.has_base_price) {
                itemInit.has_base_price = false;
                itemInit.removeIVA = itemInit.has_base_price;
              }
              measuresInit.push(itemInit);
            }
          });
          this.set('defaultUOM', defaultUOM);
          this.set('measures', measures);
          this.set('measuresInit', measuresInit);

          let properties = [];

          product.properties.forEach(property => {
            let item = Object.assign({}, property);

            let nameItem = this.get('propertyNames').find(i => {
              return i.id == item.name;
            });

            item.display_name = nameItem.displayName;
            properties.push(item);
          });

          this.set('properties', properties);

          this.set('productProviders', Array.from(product.providers));
        } else {
          this.set('title', 'Crear Producto');
          this.set('measures', []);
          this.set('properties', []);
          this.set('productProviders', []);
          this.set('synonyms', []);
        }
      }).catch(error => {
        (0, _emberSweetalert.default)({
          title: "Error",
          type: "error",
          text: error.responseJSON.message,
          showConfirmButton: true
        }).then(() => {
          $('.modal').modal('hide');
        });
      });

      return this;
    },

    done: function saveCallBack(saveCallback) {
      this.set('saveCallback', saveCallback);
    },

    actions: {
      submit: function () {
        let productToSave = Object.assign({}, this.get('product'));

        productToSave.has_promotion = false;
        try {
          productToSave.default_uom_id = this.get('defaultUOM').uom_id;
        } catch (error) {
          return (0, _emberSweetalert.default)({
            title: "Error",
            type: "error",
            text: "Error al guardar, revise Unidad de Medida por Defecto",
            showConfirmButton: true
          }).then(() => {});
        }
        productToSave.subcategory_id = this.get('subcategory').subcategory_id;

        let tagArrayToString = function (array) {
          let tags = [];
          array.forEach(tag => {
            tags.push(`"${tag}"`);
          });

          return tags.join();
        };

        productToSave.tags = tagArrayToString(this.get('tags'));
        productToSave.synonyms = tagArrayToString(this.get('synonyms'));

        let measures = [];
        this.get('measures').forEach(measure => {
          let item = Object.assign({}, measure);
          item.uom_id = item.uom.uom_id;
          delete item.uom;

          if (item.promotion_price != null && item.promotion_price.length > 0) {
            item.has_promotion = true;
            productToSave.has_promotion = true;
          } else {
            item.promotion_price = null;
            item.has_promotion = false;
          }

          measures.push(item);
        });
        measures.map(measure => {
          measure.removeIVA = measure.has_base_price;
        });

        productToSave.measures = measures;

        let properties = [];
        this.get('properties').forEach(property => {
          let item = Object.assign({}, property);
          delete item.display_name;

          properties.push(item);
        });
        productToSave.properties = properties;

        let providers = [];
        this.get('productProviders').forEach(provider => {
          let item = Object.assign({}, provider);

          providers.push({
            provider_id: item.provider_id
          });
        });
        productToSave.providers = providers;

        let promise = null;

        if (productToSave.product_id) {
          const updated_by = `"${this.get('session.currentUser.displayName')}" <${this.get('session.currentUser.email')}>`;
          productToSave.measures = (0, _measureUtil.retrieveArrayOfStatus)(this.get('measuresInit'), measures, updated_by);
          productToSave.isMeasuresWithStates = true;
          promise = this.get('productService').update(productToSave.product_id, productToSave);
        } else {
          promise = this.get('productService').save(productToSave);
        }

        return promise.then(() => {
          this.get('saveCallback')();
          jQuery('.modal').modal('hide');
        }).catch(error => {
          (0, _emberSweetalert.default)({
            title: "Error",
            type: "error",
            text: error.responseJSON.message,
            showConfirmButton: true
          }).then(() => {});
        });
      },
      calculateIva: function (index) {
        let temp = this.get('measures')[index];
        if (temp.has_base_price) {
          let priceIva = (temp.price / 1.14942528735632185).toFixed(2);
          let priceIvaNext = (priceIva * 1.14942528735632185).toFixed(2);

          Ember.set(temp, "price", priceIva);
          Ember.set(temp, "priceIva", priceIvaNext);
        } else {
          let priceIva = (temp.price * 1.14942528735632185).toFixed(2);
          let priceIvaNext = (priceIva * 1.14942528735632185).toFixed(2);

          Ember.set(temp, "price", priceIva);
          Ember.set(temp, "priceIva", priceIvaNext);
        }
      },
      updateText: function (editor) {
        console.log(editor.root.innerHTML);
        this.set('product.description', editor.root.innerHTML);
      },

      addTag(tag) {
        this.get('tags').pushObject(tag);
      },

      removeTagAtIndex(index) {
        this.get('tags').removeAt(index);
      },

      addSynonym(synonym) {
        this.get('synonyms').pushObject(synonym);
      },

      removeSynonymAtIndex(index) {
        this.get('synonyms').removeAt(index);
      },

      addMeasure() {
        let productUOM = {
          uom_quantity: this.get('UOMQuantity'),
          price: this.get('price'),
          cost: this.get('cost'),
          promotion_price: this.get('promotion'),
          description: '',
          minimum_amount: this.get('maximum'),
          maximum_amount: this.get('minimum'),
          uom: this.get('UOM')
        };

        this.get('measures').pushObject(productUOM);
        this._resetProductUOMSelections();
      },

      addProperty() {
        let item = this.get('properties').find(property => {
          return property.name == this.get('propertyNameSelected.id');
        });

        if (!item) {
          item = {
            name: this.get('propertyNameSelected.id'),
            display_name: this.get('propertyNameSelected.displayName'),
            value: ''
          };

          this.get('properties').pushObject(item);
        }
      },

      addProvider() {
        let item = this.get('productProviders').find(provider => {
          return provider.provider_id == this.get('provider.provider_id');
        });

        if (!item) {
          this.get('productProviders').pushObject(this.get('provider'));
        }
      },

      removeMeasure(productUOM) {
        this.get('measures').removeObject(productUOM);
      },

      removeProperty(property) {
        this.get('properties').removeObject(property);
      },

      removeProvider(provider) {
        this.get('productProviders').removeObject(provider);
      }
    },

    _loadSubcategories: function () {
      return this.get('categoryService').subcategories().then(subcategories => {

        subcategories.forEach(subcategory => {
          subcategory.displayName = `${subcategory.category.name} - ${subcategory.name}`;
        });

        this.set('subcategories', subcategories);
        this.set('subcategory', subcategories[0] || null);

        return subcategories;
      });
    },

    _loadUOMs: function () {
      return this.get('uomService').all().then(uoms => {
        this.set('uoms', uoms);
        this.set('defaultUOM', uoms[0] || null);
        this.set('UOM', this.get('uoms')[0] || null);
        this._resetProductUOMSelections();

        return uoms;
      });
    },

    _loadProduct: function (productId) {
      return this.get('productService').findById(productId);
    },

    _loadProviders: function () {
      return this.get('providerService').all().then(providers => {
        this.set('providers', providers);
        this.set('provider', this.get('providers')[0] || null);

        return providers;
      });
    },

    _resetProductUOMSelections: function () {
      this.set('UOMQuantity', '1');
      this.set('cost', '0.00');
      this.set('price', '0.00');
      this.set('promotion', '');
      this.set('maximum', '1');
      this.set('minimum', '1');
    }
  });
});