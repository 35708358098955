define('panal-fresh-admin/controllers/dealer/index', ['exports', 'panal-fresh-admin/mixins/pagination-controller-mixin'], function (exports, _paginationControllerMixin) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Controller.extend(_paginationControllerMixin.default, {
		allUserService: Ember.inject.service('user'),
		editDelaerOption: Ember.inject.controller('modals/edit-dealer-option'),
		init: function () {
			this._allRole().then(() => {
				this._userSelect().then(() => {
					this._searchUserRole();
				});
			});
			return this;
		},
		filterCity: Array({
			id: "CBB",
			display: "Cochabamba"
		}, {
			id: "SCZ",
			display: "Santa Cruz"
		}, {
			id: "LPZ",
			display: "La Paz"
		}, {
			id: "EAL",
			display: "El Alto"
		}, {
			id: "ALL",
			display: "Todos"
		}),
		citySelect: {
			id: "ALL",
			display: "Todos"
		},
		dealerName: '',
		allUser: [],
		allUserSearch: [],
		allRole: [],
		onCityUpdated: Ember.observer('citySelect', function () {
			this.init();
		}),

		actions: {
			refreshModel: function () {
				this.init();
			},
			userSelect: function (userID) {
				var self = this;
				this.send('showModal', 'modals/edit-dealer-option');
				this.get('editDelaerOption').initController(userID).done(function () {
					self.send('refreshModel');
				});
			},
			search: function () {
				this.set('allUserSearch', this.get('allUser').filter(data => {
					return data.full_name.toLowerCase().includes(this.get('dealerName').toLowerCase());
				}));
			},
			clear: function () {
				this.set('dealerName', '');
				this.send('search');
			}
		},
		_userSelect: function () {
			return this.get('allUserService').allUser(this.get('citySelect.id')).then(data => {
				let user = data.filter(userName => {
					return userName.full_name;
				});
				this.set('allUser', user);
				this.send('search');
			});
		},
		_allRole: function () {
			return this.get('allUserService').allRole().then(data => {
				this.set('allRole', data);
			});
		},
		_searchUserRole: function () {
			return this.get('allUser').map(data => {
				let role = this.get('allRole').filter(dataRole => {
					return data.user_uid == dataRole.user_uid;
				});
				try {
					data.role = role[0].roles.map(idRole => {
						return idRole.name;
					});
				} catch (error) {}
			});
		}
	});
});