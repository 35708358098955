define('panal-fresh-admin/controllers/bag/index', ['exports', 'panal-fresh-admin/mixins/pagination-controller-mixin', 'ember-sweetalert'], function (exports, _paginationControllerMixin, _emberSweetalert) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend(_paginationControllerMixin.default, {
    bagService: Ember.inject.service('bag'),

    queryParams: ['query', 'enabled'],
    query: '',
    enabled: true,

    createBagController: Ember.inject.controller('modals/create-bag'),
    viewBagController: Ember.inject.controller('modals/view-bag-details'),

    onQueryUpdated: Ember.observer('query', function () {
      Ember.run.debounce(this, this.applyQuery, 1000);
    }),

    onEnabledUpdated: Ember.observer('enabled', function () {
      this.applyQuery();
    }),

    applyQuery: function () {
      this.send('resetPagination');
    },

    actions: {
      refreshModel: function () {
        Ember.getOwner(this).lookup('route:bag.index').refresh();
      },

      createBag: function () {
        var self = this;
        this.send('showModal', 'modals/create-bag');
        this.get('createBagController').initController().done(function () {
          self.send('refreshModel');
        });
      },

      editBag: function (bagId) {
        var self = this;
        this.send('showModal', 'modals/create-bag');
        this.get('createBagController').initController(bagId).done(function () {
          self.send('refreshModel');
        });
      },

      viewBag: function (bagId) {
        var self = this;
        this.send('showModal', 'modals/view-bag-details');
        this.get('viewBagController').initController(bagId).done(function () {
          self.send('refreshModel');
        });
      },

      deleteBag: function (bagId) {
        var self = this;

        (0, _emberSweetalert.default)({
          title: '¿Está seguro que desea eliminar la bolsa?',
          text: "No podras revertir este proceso.",
          type: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Si, eliminalo!'
        }).then(result => {
          if (result) {
            self._deleteBag(bagId);
          }
        });
      }
    },

    _deleteBag: function (bagId) {
      return this.get('bagService').remove(bagId).then(() => {
        this.send('refreshModel');
        (0, _emberSweetalert.default)('Eliminado!', 'La bolsa ha sido eliminado.', 'success');
      }).catch(error => {
        (0, _emberSweetalert.default)({
          title: "Error",
          type: "error",
          text: "La bolsa no puede ser eliminado porque tiene ordenes o carritos relacionados con esta bolsa.",
          showConfirmButton: true
        }).then(() => {});
      });
    }
  });
});