define('panal-fresh-admin/router', ['exports', 'panal-fresh-admin/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  const Router = Ember.Router.extend({
    location: _environment.default.locationType,
    rootURL: _environment.default.rootURL
  });

  Router.map(function () {
    this.route('category', function () {
      this.route('subcategory', {
        path: ':category_id/repository'
      });
    });

    this.route('provider', function () {
      this.route('detail', {
        path: ':provider_id/detail'
      });
    });

    this.route('measure');

    this.route('product', function () {});

    this.route('bag', function () {});

    this.route('recipe', function () {
      this.route('step', {
        path: ':recipe_id/step'
      });
    });

    this.route('order', function () {
      this.route('detail', {
        path: ':order_id'
      });

      this.route('confirmed');
    });

    this.route('suggestion', function () {});

    this.route('shopping-list', function () {
      this.route('edit', {
        path: ':shopping_list_id/edit'
      });

      this.route('purchase', {
        path: ':shopping_list_id/purchase'
      });
    });

    this.route('client', function () {});

    this.route('report', function () {});
    this.route('season', function () {});

    this.route('assign-order', function () {});
    this.route('dealer', function () {});
  });

  exports.default = Router;
});