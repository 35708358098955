define('panal-fresh-admin/helpers/md5', ['exports', 'ember-md5/helpers/md5'], function (exports, _md) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _md.default;
    }
  });
});