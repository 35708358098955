define('panal-fresh-admin/controllers/modals/update-client-prices', ['exports', 'ember-sweetalert', 'panal-fresh-admin/utils/measure-util'], function (exports, _emberSweetalert, _measureUtil) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    productService: Ember.inject.service('product'),
    clientService: Ember.inject.service('client'),
    uomService: Ember.inject.service('uom'),
    propertyFormula: Array({
      id: 'PERCENT',
      displayName: '%'
    }, {
      id: 'FIXED',
      displayName: 'Fijo'
    }),
    initController: function (productId = null) {
      this._reset();
      this.set('productId', productId);

      Promise.all([this._loadProduct(productId), this._loadClients(), this._loadUOMs()]).then(results => {
        let product = results[0];

        this.set('product', product);
        this.set('title', product.name);
        this.set('propertyFormulaSelected', this.get('propertyFormula')[0] || null);
        this.set('formula', 13);
      });

      return this;
    },

    done: function saveCallBack(saveCallback) {
      this.set('saveCallback', saveCallback);
    },

    onClientUpdated: Ember.observer('client', function () {
      let clientUID = this.get('client.user_uid');
      let productId = this.get('productId');

      this._loadClientPrices(productId, clientUID).then(clientPrices => {
        this.set('clientPrices', clientPrices);

        let copyClientPrices = [...clientPrices];
        let clientPricesInit = [];

        copyClientPrices.forEach(price => {
          let item = Object.assign({}, price);
          item.uom_id = item.uom.uom_id;
          delete item.uom;
          delete item.client;
          delete item.uom_display;

          if (item.promotion_price != null && item.promotion_price.length > 0) item.has_promotion = true;else item.promotion_price = null;

          clientPricesInit.push(item);
        });
        this.set('clientPricesInit', clientPricesInit);
      });
    }),

    actions: {
      submit: function () {
        let measures = [];
        this.get('clientPrices').forEach(price => {
          let item = Object.assign({}, price);
          item.uom_id = item.uom.uom_id;
          delete item.uom;
          delete item.client;
          delete item.uom_display;

          if (item.promotion_price != null && item.promotion_price.length > 0) {
            item.has_promotion = true;
          } else {
            item.promotion_price = null;
          }

          measures.push(item);
        });

        let clientUID = this.get('client.user_uid');
        let productId = this.get('productId');
        const updated_by = `"${this.get('session.currentUser.displayName')}" <${this.get('session.currentUser.email')}>`;
        const newMeasures = (0, _measureUtil.retrieveArrayOfStatusClientPrices)(this.get('clientPricesInit'), measures, updated_by);
        return this.get('clientService').updateProductPrice(clientUID, productId, newMeasures).then(employee => {
          this.get('saveCallback')();
          jQuery('.modal').modal('hide');
        }).catch(error => {
          (0, _emberSweetalert.default)({
            title: "Error",
            type: "error",
            text: error.responseJSON.message,
            showConfirmButton: true
          }).then(() => {});
        });
      },
      fomulaCalculate: function () {
        if (this.propertyFormulaSelected.id == 'PERCENT') {
          let percent = parseFloat(this.cost) + parseFloat(this.cost) * parseFloat(this.formula) / 100;
          if (percent != 0) this.set('price', percent);
        } else {
          this.set('price', parseFloat(this.cost) + parseFloat(this.formula));
        }
      },

      addMeasure() {
        let price = {
          uom_quantity: this.get('UOMQuantity'),
          price: this.get('price'),
          cost: this.get('cost'),
          promotion_price: this.get('promotion'),
          description: '',
          minimum_amount: this.get('maximum'),
          maximum_amount: this.get('minimum'),
          uom: this.get('UOM')
        };

        this.get('clientPrices').pushObject(price);
        this._resetProductUOMSelections();
      },

      copyMeasure(measure) {
        let price = Object.assign({}, measure);
        /*{
                uom_quantity: this.get('UOMQuantity'),
                price: this.get('price'),
                cost: this.get('cost'),
                promotion_price: this.get('promotion'),
                description: '',
                minimum_amount: this.get('maximum'),
                maximum_amount: this.get('minimum'),
                uom: this.get('UOM')
              };*/

        this.get('clientPrices').pushObject(price);
      },

      removeMeasure(price) {
        this.get('clientPrices').removeObject(price);
      }
    },

    _loadProduct: function (productId) {
      return this.get('productService').findById(productId);
    },

    _loadClientPrices: function (productId, clientUID) {
      return this.get('clientService').productPrices(clientUID, productId);
    },

    _loadClients: function () {
      return this.get('clientService').all().then(clients => {
        clients.unshift({
          user_uid: 'ALL',
          name: 'Todos los clientes'
        });

        this.set('clients', clients);
        this.set('client', this.get('clients')[0] || null);

        return clients;
      });
    },

    _loadUOMs: function () {
      return this.get('uomService').all().then(uoms => {
        this.set('uoms', uoms);
        this.set('UOM', this.get('uoms')[0] || null);
        this._resetProductUOMSelections();

        return uoms;
      });
    },

    _reset: function () {
      this.set('clientPrices', []);
    },

    _resetProductUOMSelections: function () {
      this.set('UOMQuantity', '1');
      this.set('cost', '0.00');
      this.set('price', '0.00');
      this.set('formula', 0);
      this.set('promotion', '');
      this.set('maximum', '1');
      this.set('minimum', '1');
    }
  });
});