define('panal-fresh-admin/controllers/modals/create-uom', ['exports', 'ember-sweetalert'], function (exports, _emberSweetalert) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    uomService: Ember.inject.service('uom'),

    initController: function (uomId = null) {
      if (uomId) {
        this._loadUom(uomId).then(uom => {
          this.set('uom', uom);
          this.set('title', 'Editar unidad de medida');
        });
      } else {
        this.set('uom', {
          name: '',
          plural: '',
          description: ''
        });
        this.set('title', 'Crear unidad de medida');
      }
      return this;
    },

    done: function saveCallBack(saveCallback) {
      this.set('saveCallback', saveCallback);
    },

    actions: {
      submit: function () {
        if (this.get('uom.name') == '') {
          (0, _emberSweetalert.default)({
            title: "Error",
            type: "error",
            text: "El campo nombre es requerido.",
            showConfirmButton: true
          }).then(() => {});
        } else {
          this._saveUom();
        }
      }
    },

    _saveUom() {
      let uomToSave = Object.assign({}, this.get('uom'));
      let promise = null;
      if (uomToSave.uom_id) {
        const uomId = uomToSave.uom_id;
        delete uomToSave.uom_id;
        promise = this.get('uomService').update(uomId, uomToSave);
      } else {
        promise = this.get('uomService').save(uomToSave);
      }

      return promise.then(() => {
        this.get('saveCallback')();
        jQuery('.modal').modal('hide');
      }).catch(error => {
        (0, _emberSweetalert.default)({
          title: "Error",
          type: "error",
          text: error.responseJSON.message,
          showConfirmButton: true
        }).then(() => {});
      });
    },

    _loadUom: function (uomId) {
      return this.get('uomService').findById(uomId);
    }

  });
});