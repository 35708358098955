define('panal-fresh-admin/controllers/modals/view-provider-details', ['exports', 'ember-sweetalert'], function (exports, _emberSweetalert) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({

    providerService: Ember.inject.service('provider'),
    firebaseApp: Ember.inject.service(),
    isBackgroundURL: false,

    initController: function (providerId = null, val) {
      val == 'background_url' ? this.isBackgroundURL = true : this.isBackgroundURL = false;
      this._reset();

      this._loadProvider(providerId).then(provider => {
        this.set('provider', provider);
        !this.isBackgroundURL ? this.set('currentImagePath', provider.image) : this.set('currentImagePath', provider.background_image_url);
        this.set('title', provider.name);
      });
      return this;
    },

    done: function saveCallBack(saveCallback) {
      this.set('saveCallback', saveCallback);
    },

    actions: {
      submit: function () {
        let imageFile = this.get('currentImageFile');

        if (!imageFile) {
          return;
        }

        const storage = this.get('firebaseApp').storage();

        let metadata = {
          'contentType': imageFile.type
        };

        let providerId = this.get('provider.provider_id');
        let fileExtension = imageFile.name.split('.').pop();

        let regionPath = '';
        let region = localStorage.getItem('region');
        if (region) {
          let savedRegion = JSON.parse(region);

          if (savedRegion && savedRegion.schema && savedRegion.schema != 'cochabamba') {
            regionPath = `${savedRegion.schema}/`;
          }
        }

        let providerImagePath = `images/provider/${regionPath}${providerId}.${fileExtension}`;
        if (this.isBackgroundURL) {
          providerImagePath = `images/provider/${regionPath}background${providerId}.${fileExtension}`;
        }

        let storageRef = storage.ref();
        let uploadImageRef = storageRef.child(providerImagePath);
        var uploadTask = uploadImageRef.put(imageFile, metadata);

        uploadTask.on('state_changed', snapshot => {
          var progress = snapshot.bytesTransferred / snapshot.totalBytes * 100;
          this.set('progress', progress);
          switch (snapshot.state) {
            case 'paused':
              this.set('status', 'Upload is paused');
              break;
            case 'running':
              this.set('status', 'Upload is running');
              break;
          }
        }, error => {
          let message = '';
          switch (error.code) {
            case 'storage/unauthorized':
              message = 'El usuario no tiene permiso para subir imagenes.';
              break;
            case 'storage/canceled':
              message = 'El proceso fue cancelado.';
              break;
            case 'storage/unknown':
              message = 'El proceso fallo.';
              break;
          }

          (0, _emberSweetalert.default)({
            title: "Error",
            type: "error",
            text: message,
            showConfirmButton: true
          }).then(() => {});
        }, () => {
          if (this.isBackgroundURL) {
            this._updateProviderBackgroundImage(uploadTask.snapshot.downloadURL).then(() => {
              this.get('saveCallback')();
              jQuery('.modal').modal('hide');
            });
          } else {
            this._updateProviderImage(uploadTask.snapshot.downloadURL).then(() => {
              this.get('saveCallback')();
              jQuery('.modal').modal('hide');
            });
          }
        });
      },

      didSelectFile: function (fileList) {
        if (fileList.length == 0) {
          return;
        }

        let imageFile = fileList[0];

        var imageTempPath = URL.createObjectURL(imageFile);
        this.set('currentImagePath', imageTempPath);
        this.set('currentImageFile', imageFile);
      }
    },

    _loadProvider: function (providerId) {
      return this.get('providerService').findById(providerId);
    },

    _updateProviderImage: function (newImageURL) {
      let providerId = this.get('provider.provider_id');

      return this.get('providerService').update(providerId, {
        image: newImageURL
      });
    },
    _updateProviderBackgroundImage: function (newImageURL) {
      let providerId = this.get('provider.provider_id');

      return this.get('providerService').update(providerId, {
        background_image_url: newImageURL
      });
    },

    _reset: function () {
      this.set('currentImageFile', null);
      this.set('progress', 0);
    }
  });
});