define('panal-fresh-admin/controllers/modals/print-invoice', ['exports', 'ember-sweetalert', 'qrcode'], function (exports, _emberSweetalert, _qrcode) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    orderService: Ember.inject.service('order'),

    json: null,

    initController: function (orderId) {
      this._reset();
      this.send('prinInvoice');
      this._loadOrder(orderId).then(order => {
        console.log(order);
        this.set('order', order);
        this.set('title', `Imprimir orden: ${order.order_id} (${order.status.display_name})`);
      });
      return this;
    },

    done: function saveCallBack(saveCallback) {
      this.set('saveCallback', saveCallback);
    },

    actions: {
      submit: function () {
        $('#print-container').printThis({
          debug: false,
          printContainer: true,
          importCSS: true,
          importStyle: true,
          formValues: true,
          copyTagClasses: true,
          canvas: true

        });
      },

      prinInvoice() {
        var self = this;
        let payload = this.get('payload');
        let token = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX2lkIjoxLCJjbGllbnRfaWQiOiJkZW9FTU8iLCJpYXQiOjE1NTk3Mzg3OTEsImV4cCI6MTU1OTc2NzU5MSwiaXNzIjoicmljaGkucGFuYWxpdG8ifQ.fNUStdC-BoZ5VDlrO_oqm6f48_WuS8asv03oH9O93tA';
        console.log(payload);
        $.ajax({
          type: "GET",
          contentType: "application/json",
          headers: {
            'Accept': "application/json",
            'x-access-token': token
          },
          url: "http://panal.kumisolutions.com:3031/api/invoice/277?external=true",
          success: function (data) {
            console.log(data);
            self.set('json', data);
          }
        });
      }
    },

    _reset: function () {
      this.set('order', null);
      this.set('title', `Loading...`);
    },

    _loadOrder: function (orderId) {
      return this.get('orderService').findById(orderId);
    }
  });
});