define('panal-fresh-admin/services/shopping-list', ['exports', 'panal-fresh-admin/mixins/api-service-mixin'], function (exports, _apiServiceMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend(_apiServiceMixin.default, {
    init: function () {
      this._super(...arguments);
    },

    findById: function (listId) {
      return this.getRequest(`/v1/shopping_list/${listId}`);
    },

    save: function (payload) {
      return this.post('/v1/shopping_list', payload);
    },

    all: function (queryParams = {}) {
      return this.getRequest('/v1/shopping_list', queryParams);
    },

    add: function (shoppingListId, orderId) {
      return this.post(`/v1/shopping_list/${shoppingListId}/order/${orderId}`);
    },

    update: function (shoppingListId, payload) {
      return this.patch(`/v1/shopping_list/${shoppingListId}`, payload);
    },

    //
    // GROUP SERVICES
    //

    groupById: function (groupId) {
      return this.getRequest(`/v1/shopping_list_group/${groupId}`);
    },

    removeGroup: function (groupId) {
      return this.delete(`/v1/shopping_list_group/${groupId}`);
    },

    saveGroup: function (payload) {
      return this.post(`/v1/shopping_list_group`, payload);
    },

    updateGroup: function (groupId, payload) {
      return this.patch(`/v1/shopping_list_group/${groupId}`, payload);
    },

    //

    itemById: function (itemId) {
      return this.getRequest(`/v1/shopping_list_item/${itemId}`);
    },

    removeItem: function (itemId) {
      return this.delete(`/v1/shopping_list_item/${itemId}`);
    },

    saveItem: function (payload) {
      return this.post(`/v1/shopping_list_item`, payload);
    },

    updateItem: function (itemId, payload) {
      return this.patch(`/v1/shopping_list_item/${itemId}`, payload);
    },

    //

    purchase: function (listId) {
      return this.getRequest(`/v1/shopping_list/${listId}/purchase`);
    }
  });
});