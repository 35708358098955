define('panal-fresh-admin/helpers/literal-number', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.literalNumber = literalNumber;


  const DECIMAL_QUANTITY = 2;

  function literalNumber([value = 0, currency = 'Bs']) {
    return Ember.String.htmlSafe(`${_literal(value)} ${currency}.`);
  }

  let _units = function (number) {
    switch (number) {
      case 1:
        return 'un';
      case 2:
        return 'dos';
      case 3:
        return 'tres';
      case 4:
        return 'cuatro';
      case 5:
        return 'cinco';
      case 6:
        return 'seis';
      case 7:
        return 'siete';
      case 8:
        return 'ocho';
      case 9:
        return 'nueve';
    }

    return '';
  };

  let _tens = function (number) {

    let ten = Math.floor(number / 10);
    let unit = number - ten * 10;

    switch (ten) {
      case 1:
        switch (unit) {
          case 0:
            return "diez";
          case 1:
            return "once";
          case 2:
            return "doce";
          case 3:
            return "trece";
          case 4:
            return "catorce";
          case 5:
            return "quince";
          default:
            return "dieci" + _units(unit);
        }
      case 2:
        switch (unit) {
          case 0:
            return "veinte";
          default:
            return "veinti" + _units(unit);
        }
      case 3:
        return _tensAnd("treinta", unit);
      case 4:
        return _tensAnd("cuarenta", unit);
      case 5:
        return _tensAnd("cincuenta", unit);
      case 6:
        return _tensAnd("sesenta", unit);
      case 7:
        return _tensAnd("setenta", unit);
      case 8:
        return _tensAnd("ochenta", unit);
      case 9:
        return _tensAnd("noventa", unit);
      case 0:
        return _units(unit);
    }
  };

  let _tensAnd = function (fatherTen, units) {
    return units > 0 ? fatherTen + " y " + _units(units) : fatherTen;
  };

  let _hundreds = function (number) {
    let hundreds = Math.floor(number / 100);
    let tens = number - hundreds * 100;

    switch (hundreds) {
      case 1:
        return tens > 0 ? "ciento " + _tens(tens) : 'cien';
      case 2:
        return "doscientos " + _tens(tens);
      case 3:
        return "trescientos " + _tens(tens);
      case 4:
        return "cuatrocientos " + _tens(tens);
      case 5:
        return "quinientos " + _tens(tens);
      case 6:
        return "seiscientos " + _tens(tens);
      case 7:
        return "setecientos " + _tens(tens);
      case 8:
        return "ochocientos " + _tens(tens);
      case 9:
        return "novecientos " + _tens(tens);
    }

    return _tens(tens);
  };

  let _section = function (number, divider, singular, plural) {
    let hundreds = Math.floor(number / divider);
    let rest = number - hundreds * divider;

    let words = '';

    if (hundreds > 1) {
      words = `${_hundreds(hundreds)} ${plural}`;
    } else if (hundreds > 0) {
      words = singular;
    }

    return words;
  };

  let _thousands = function (number) {
    const divider = 1000;
    let hundreds = Math.floor(number / divider);
    let rest = number - hundreds * divider;

    let thousandsString = _section(number, divider, "un mil", "mil");
    let hundredsString = _hundreds(rest);

    if (thousandsString == "") {
      return hundredsString;
    }

    return `${thousandsString} ${hundredsString}`;
  };

  let _millions = function (number) {
    const divider = 1000000;
    let hundreds = Math.floor(number / divider);
    let rest = number - hundreds * divider;

    let millonsString = _section(number, divider, "un millon de", "millones de");
    let thousandsString = _thousands(rest);

    if (millonsString == '') {
      return thousandsString;
    }

    return `${millonsString} ${thousandsString}`;
  };

  let _literal = function (number) {
    let integers = Math.floor(number);
    let cents = Math.round(number * 100) - Math.floor(number) * 100;

    let centsLiteral = 'con ' + (cents < 10 ? '0' : '') + `${cents}` + '/100';

    return integers == 0 ? `cero ${centsLiteral}` : `${_millions(integers)} ${centsLiteral}`;
  };

  exports.default = Ember.Helper.helper(literalNumber);
});