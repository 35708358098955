define('panal-fresh-admin/services/order', ['exports', 'panal-fresh-admin/mixins/api-service-mixin'], function (exports, _apiServiceMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend(_apiServiceMixin.default, {
    init: function () {
      this._super(...arguments);
    },

    all: function (queryParams = {}) {
      return this.getRequest(`/v1/order`, queryParams);
    },

    findById: function (orderId) {
      return this.getRequest(`/v1/order/${orderId}`);
    },

    findOrderByUserUid(userUid) {
      return this.getRequest(`/v1/user/${userUid}/order`);
    },

    update: function (orderId, payload = {}) {
      return this.patch(`/v1/order/${orderId}`, payload);
    },

    downloadExcel: function (payload) {
      return this.getExcel(payload);
    },
    updateLocation: function (orderId, payload) {
      return this.patch(`/v1/order/${orderId}`, payload);
    }

  });
});