define('panal-fresh-admin/controllers/modals/edit-order-bag', ['exports', 'ember-sweetalert'], function (exports, _emberSweetalert) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    orderBagService: Ember.inject.service('order-bag'),

    initController: function (orderId = null, bagId = null) {
      this._loadOrderBag(orderId, bagId).then(orderBag => {
        this.set('orderId', orderId);
        this.set('orderBag', orderBag);
        this.set('title', `Editar ${orderBag.bag.name}`);
      });
      return this;
    },

    done: function saveCallBack(saveCallback) {
      this.set('saveCallback', saveCallback);
    },

    actions: {
      submit: function () {
        if (this.get('orderBag.quantity') == '' || this.get('orderBag.price') == '') {
          (0, _emberSweetalert.default)({
            title: "Error",
            type: "error",
            text: "Los campos requeridos son obligatorios.",
            showConfirmButton: true
          }).then(() => {});
        } else {
          this._updateBag();
        }
      }
    },

    _updateBag: function () {
      let bagToUpdate = Object.assign({}, this.get('orderBag'));
      let payload = {
        'quantity': bagToUpdate.quantity,
        'price': bagToUpdate.price
      };
      return this.get('orderBagService').update(this.get('orderId'), bagToUpdate.bag.bag_id, payload).then(_ => {
        this.get('saveCallback')();
        jQuery('.modal').modal('hide');
      }).catch(error => {
        (0, _emberSweetalert.default)({
          title: "Error",
          type: "error",
          text: error.responseJSON.message,
          showConfirmButton: true
        }).then(() => {});
      });
    },

    _loadOrderBag: function (orderId, bagId) {
      return this.get('orderBagService').findById(orderId, bagId);
    }
  });
});