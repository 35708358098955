define('panal-fresh-admin/controllers/recipe/step', ['exports', 'panal-fresh-admin/mixins/pagination-controller-mixin'], function (exports, _paginationControllerMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend(_paginationControllerMixin.default, {
    stepService: Ember.inject.service('step'),

    queryParams: ['query', 'enabled'],
    query: '',
    enabled: true,

    viewStepController: Ember.inject.controller('modals/view-step-details'),

    onQueryUpdated: Ember.observer('query', function () {
      Ember.run.debounce(this, this.applyQuery, 1000);
    }),

    onEnabledUpdated: Ember.observer('enabled', function () {
      this.applyQuery();
    }),

    applyQuery: function () {
      this.send('resetPagination');
    },

    actions: {
      refreshModel: function () {
        Ember.getOwner(this).lookup('route:recipe.step').refresh();
      },

      viewStep: function (stepId) {
        var self = this;
        let recipeId = this.get('recipeId');
        this.send('showModal', 'modals/view-step-details');
        this.get('viewStepController').initController(stepId, recipeId).done(function () {
          self.send('refreshModel');
        });
      }

    }

  });
});