define('panal-fresh-admin/routes/category/subcategory', ['exports', 'panal-fresh-admin/config/environment', 'panal-fresh-admin/mixins/pagination-route-mixin', 'panal-fresh-admin/mixins/protected-route-mixin'], function (exports, _environment, _paginationRouteMixin, _protectedRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_protectedRouteMixin.default, _paginationRouteMixin.default, {

    model: function (queryParams) {
      var url = `${_environment.default.APP.API_HOST}/v1/category/${queryParams.category_id}/subcategory`;
      var urlCategory = `${_environment.default.APP.API_HOST}/v1/category/${queryParams.category_id}`;
      this.set('idCategory', queryParams.category_id);
      return this.findPaged({
        type: 'GET',
        url: urlCategory,
        headers: {}
      }).then(category => {
        this.set('Name', category.name);
        return this.findPaged({
          type: 'GET',
          url: url,
          headers: {}
        }, queryParams);
      });
    },

    setupController: function (controller, model, transition) {
      this._super(controller, model);
      controller.set('categoryId', this.get('idCategory'));
      controller.set('categoryName', this.get('Name'));
      controller.set('errors', []);
    }
  });
});