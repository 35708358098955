define('panal-fresh-admin/controllers/modals/edit-dealer-option', ['exports', 'ember-sweetalert'], function (exports, _emberSweetalert) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    queryParams: ['query', 'enabled'],
    userService: Ember.inject.service('user'),
    dealer: [],
    orders: [],
    role: [],
    admin: false,
    shopper: false,
    filterCity: Array({
      id: "CBB",
      display_name: "Cochabamba"
    }, {
      id: "SCZ",
      display_name: "Santa Cruz"
    }, {
      id: "LPZ",
      display_name: "La Paz"
    }, {
      id: "EAL",
      display_name: "El Alto"
    }, {
      id: "NULL",
      display_name: "Ninguno"
    }),
    citySelect: {
      id: "NULL",
      display_name: "Ninguno"
    },
    initController: function (user) {
      this.set('admin', false);
      this.set('shopper', false);
      this.set('dealer', user);
      if (user.city) {
        this.set('citySelect.id', user.city.id);
        this.set('citySelect.display_name', user.city.display_name);
      } else {
        this.set('citySelect.id', 'NULL');
        this.set('citySelect.display_name', 'Ninguno');
      }
      this._getOrderAssignne(user.user_uid);
      this._getUserRole(user.user_uid);
      return this;
    },
    done: function saveCallBack(saveCallback) {
      this.set('saveCallback', saveCallback);
    },
    onEnabledUpdated: Ember.observer('enabled', function () {
      this.applyQuery();
    }),
    message: "añadir Productos",
    done: function saveCallBack(saveCallback) {
      this.set('saveCallback', saveCallback);
    },
    actions: {
      submit: function () {
        this.send('updateRole');
      },
      updateRole: function () {
        var promise = null;
        promise = this._applyRole(1, this.get('admin')).then(this._applyRole(2, this.get('shopper'))).then(() => {
          let payload = { city: this.get('citySelect.id') };
          this.get('userService').editUserCity(this.get('dealer.user_uid'), payload);
        });
        promise.then(() => {
          this.get('saveCallback')();
          jQuery('.modal').modal('hide');
        });
      }
    },
    _applyRole: function (idRole, status) {
      if (status) {
        let payload = {
          user_uid: this.get('dealer.user_uid'),
          role_id: idRole
        };
        return this.get('userService').updateRole(this.get('dealer.user_uid'), payload);
      } else {
        return this.get('userService').deleteRole(this.get('dealer.user_uid'), idRole);
      }
    },
    _getUserRole: function (user_uid) {
      return this.get('userService').getRole(user_uid).then(data => {
        try {
          let role = data[0].roles.map(rol => {
            if (rol.role_id == 1) this.set('admin', true);
            if (rol.role_id == 2) this.set('shopper', true);
          });
          this.set('role', data[0].roles);
        } catch (error) {
          this.set('role', null);
        }
      });
    },
    _getOrderAssignne: function (user_uid) {
      return this.get('userService').assigneedOrder(user_uid).then(data => {
        this.set('orders', data);
      });
    }
  });
});