define('panal-fresh-admin/components/qr-code', ['exports', 'qrcode/components/qr-code'], function (exports, _qrCode) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _qrCode.default;
    }
  });
});