define('panal-fresh-admin/services/user', ['exports', 'panal-fresh-admin/mixins/api-service-mixin'], function (exports, _apiServiceMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend(_apiServiceMixin.default, {
    init: function () {
      this._super(...arguments);
    },

    shoppers: function () {
      return this.getRequest('/v1/role/2/user');
    },

    roles: function (userUID) {
      return this.getRequest(`/v1/user/${userUID}/role`);
    },

    getShoppers: function (cityId) {
      return this.getRequest(`/v1/user_configuration/role/2/city/${cityId}`);
    },

    allUser: function (city) {
      if (city == 'ALL') return this.getRequest(`/v1/user_configuration`);
      return this.getRequest(`/v1/user_configuration?filters=city:${city}`);
    },

    assigneedOrder: function (userId) {
      return this.getRequest(`/v1/order?filters[status]=CONFIRMED&filters[assignee_uid]=${userId}`);
    },

    getRole: function (userUID) {
      return this.getRequest(`/v1/user/${userUID}/role`);
    },

    updateRole: function (userUID, payload) {
      return this.post(`/v1/user_role`, payload).catch(error => console.error(error.message));
    },

    deleteRole: function (userUID, idRole) {
      return this.delete(`/v1/user_role/${userUID}/${idRole}`).catch(error => console.error(error.message));
    },

    allRole: function () {
      return this.getRequest(`/v1/user_role`);
    },

    editUserCity: function (userUID, payload) {
      if (payload.city == "NULL") return null;
      return this.patch(`/v1/user_configuration/${userUID}`, payload);
    }
  });
});