define('panal-fresh-admin/services/order-item', ['exports', 'panal-fresh-admin/mixins/api-service-mixin'], function (exports, _apiServiceMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend(_apiServiceMixin.default, {
    init: function () {
      this._super(...arguments);
    },

    all: function (orderId) {
      return this.getRequest(`/v1/order/${orderId}/item`);
    },

    findById: function (orderId, productId) {
      return this.getRequest(`/v1/order/${orderId}/item/${productId}`);
    },

    update: function (orderId, productId, payload) {
      return this.patch(`/v1/order/${orderId}/item/${productId}`, payload);
    },

    remove: function (orderId, productId) {
      this.delete(`/v1/order/${orderId}/item/${productId}`);
    },

    addItem: function (orderId, productId, payload) {
      return this.post(`/v1/order/${orderId}/item`, payload);
    }
  });
});