define('panal-fresh-admin/routes/order/confirmed', ['exports', 'panal-fresh-admin/config/environment', 'panal-fresh-admin/mixins/protected-route-mixin'], function (exports, _environment, _protectedRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_protectedRouteMixin.default, {
    orderService: Ember.inject.service('order'),

    model: function (queryParams) {
      var params = {
        filters: 'status:CONFIRMED'
      };

      return this.get('orderService').all(params);
    },

    setupController: function (controller, model, transition) {
      this._super(controller, model, transition);

      let items = [];
      model.forEach(order => {
        order.items.forEach(item => {
          let duplicated = items.find(saved => {
            return saved.product.product_id == item.product.product_id && saved.uom_quantity == item.uom_quantity && saved.uom.uom_id == item.uom.uom_id;
          });

          if (duplicated) {
            duplicated.quantity = duplicated.quantity + item.quantity;
            if (item.description && item.description.length > 0) {
              duplicated.description = `${duplicated.description || ''}|${item.description}`;
            }
          } else {
            items.push(item);
          }
        });
      });

      items.sort((itemA, itemB) => {
        return itemA.product.name > itemB.product.name;
      });

      controller.set('items', items);
      controller.set('errors', []);
    }
  });
});