define('panal-fresh-admin/controllers/modals/add-to-shopping-list', ['exports', 'ember-sweetalert', 'moment'], function (exports, _emberSweetalert, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    shoppingListService: Ember.inject.service('shopping-list'),

    initController: function (orderId) {
      this._loadShoppingLists();
      this.set('orderId', orderId);
      return this;
    },

    done: function saveCallBack(saveCallback) {
      this.set('saveCallback', saveCallback);
    },

    actions: {
      submit: function () {
        let orderId = this.get('orderId');
        let shoppingListId = this.get('shoppingList.shopping_list_id');

        return this.get('shoppingListService').add(shoppingListId, orderId).then(() => {
          this.get('saveCallback')();
          jQuery('.modal').modal('hide');
        }).catch(error => {
          (0, _emberSweetalert.default)({
            title: "Error",
            type: "error",
            text: error.responseJSON.message,
            showConfirmButton: true
          }).then(() => {});
        });
      }
    },

    _loadShoppingLists() {
      return this.get('shoppingListService').all({
        filters: 'status:DESIGNING'
      }).then(shoppingLists => {
        shoppingLists.forEach(list => {
          let dateString = (0, _moment.default)(list.date).format('dddd D [de] MMMM [de] YYYY');
          list.display = `${dateString} - ${list.user.displayName}`;
        });

        this.set('shoppingLists', shoppingLists);
        this.set('shoppingList', this.get('shoppingLists')[0] || null);

        return shoppingLists;
      });
    }

  });
});