define('panal-fresh-admin/controllers/order/confirmed', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    actions: {
      export: function () {
        var data = [['Producto', 'Cantidad', 'Medida', 'Costo', 'Descripción']];

        this.get('items').forEach(item => {
          let itemData = [];
          itemData.push(item.product.name);
          itemData.push(item.quantity);
          itemData.push(`${item.uom_quantity} ${item.uom.name}`);
          itemData.push(item.cost);
          itemData.push(item.description);

          data.push(itemData);
        });

        this.get('excel').export(data, {
          sheetName: 'Ordenes',
          fileName: 'orders.xlsx'
        });
      }
    }
  });
});