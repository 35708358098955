define('panal-fresh-admin/services/order-bag', ['exports', 'panal-fresh-admin/mixins/api-service-mixin'], function (exports, _apiServiceMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend(_apiServiceMixin.default, {
    init: function () {
      this._super(...arguments);
    },

    all: function (orderId) {
      return this.getRequest(`/v1/order/${orderId}/bag`);
    },

    findById: function (orderId, bagId) {
      return this.getRequest(`/v1/order/${orderId}/bag/${bagId}`);
    },

    update: function (orderId, bagId, payload) {
      return this.patch(`/v1/order/${orderId}/bag/${bagId}`, payload);
    }
  });
});