define('panal-fresh-admin/controllers/suggestion/index', ['exports', 'panal-fresh-admin/mixins/pagination-controller-mixin', 'ember-sweetalert'], function (exports, _paginationControllerMixin, _emberSweetalert) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend(_paginationControllerMixin.default, {
    suggestionService: Ember.inject.service('suggestion'),

    actions: {
      refreshModel: function () {
        Ember.getOwner(this).lookup('route:suggestion.index').refresh();
      },

      deleteSuggestion: function (suggestionId) {
        var self = this;

        (0, _emberSweetalert.default)({
          title: '¿Está seguro que desea eliminar la sugerencia?',
          text: "No podras revertir este proceso.",
          type: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Si, eliminalo!'
        }).then(result => {
          if (result) {
            self._deleteSuggestion(suggestionId);
          }
        });
      }
    },

    _deleteSuggestion: function (suggestionId) {
      return this.get('suggestionService').remove(suggestionId).then(() => {
        this.send('refreshModel');
        (0, _emberSweetalert.default)('Eliminado!', 'La sugerencia ha sido eliminada.', 'success');
      }).catch(error => {
        (0, _emberSweetalert.default)({
          title: "Error",
          type: "error",
          text: "La sugerencia no puede ser eliminada.",
          showConfirmButton: true
        }).then(() => {});
      });
    }
  });
});