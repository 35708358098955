define('panal-fresh-admin/services/provider', ['exports', 'panal-fresh-admin/mixins/api-service-mixin'], function (exports, _apiServiceMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend(_apiServiceMixin.default, {
    init: function () {
      this._super(...arguments);
    },

    all: function () {
      return this.getRequest('/v1/provider');
    },
    findById: function (providerId) {
      return this.getRequest(`/v1/provider/${providerId}`);
    },
    save: function (payload) {
      return this.post('/v1/provider', payload);
    },

    update: function (providerId, payload) {
      return this.patch(`/v1/provider/${providerId}`, payload);
    },

    remove: function (providerId) {
      return this.delete(`/v1/provider/${providerId}`);
    }
  });
});