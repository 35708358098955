define('panal-fresh-admin/mixins/api-service-mixin', ['exports', 'panal-fresh-admin/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({

    buildURL: function (path) {
      return `${_environment.default.APP.API_HOST}${path}`;
    },

    buildHeaders: function (headers = {}) {
      let commonHeaders = {};
      let authorization = localStorage.getItem('userUID');

      if (authorization) {
        commonHeaders.Authorization = authorization;
      }

      let region = localStorage.getItem('region');
      if (region) {
        let savedRegion = JSON.parse(region);

        if (savedRegion && savedRegion.schema) {
          commonHeaders['X-Region'] = savedRegion.schema;
        }
      }

      return Object.assign(commonHeaders, headers);
    },

    post: function (path = '/', payload = {}, headers = {}) {
      return this._sendJSONRequest('POST', path, payload, headers);
    },

    patch: function (path = '/', payload = {}, headers = {}) {
      return this._sendJSONRequest('PATCH', path, payload, headers);
    },

    delete: function (path = '/', payload = {}, headers = {}) {
      return this._sendJSONRequest('DELETE', path, payload, headers);
    },

    _sendJSONRequest: function (type, path = '/', payload = {}, headers = {}) {
      let url = this.buildURL(path);

      return Promise.resolve(Ember.$.ajax({
        type: type,
        url: url,
        dataType: 'json',
        contentType: 'application/json',
        processData: false,
        data: JSON.stringify(payload),
        headers: this.buildHeaders(headers)
      }));
    },

    getRequest: function (path = '/', queryParams = {}, headers = {}) {
      let url = this.buildURL(path);

      return Promise.resolve(Ember.$.ajax({
        type: 'GET',
        url: url,
        data: queryParams,
        headers: this.buildHeaders(headers)
      }));
    },
    getExcel(payload) {
      return Promise.resolve(this._downloadExcel(payload));
    },
    _downloadExcel: function (payload) {
      let url = this.buildURL('/v1/order/report-xlsx');
      if (payload.status) {
        url = this.buildURL(`/v1/order/report-xlsx?filters=status%3A${payload.status}`);
      }
      var settings = {
        "url": url,
        "method": "POST",
        "timeout": 0,
        "headers": {
          "Authorization": localStorage.getItem('userUID'),
          "x-region": JSON.parse(localStorage.getItem('region')).schema,
          "Content-Type": "application/json"
        },
        xhrFields: {
          responseType: 'blob'
        },
        "data": JSON.stringify(payload),
        success: function (data, status, xhr) {
          let name = xhr.getResponseHeader('File-Name');
          var a = document.createElement('a');
          var url = window.URL.createObjectURL(data);
          a.href = url;
          a.download = name;
          document.body.append(a);
          a.click();
          a.remove();
          window.URL.revokeObjectURL(url);
        }
      };
      return Promise.resolve(Ember.$.ajax(settings).done(function (response) {}));
    }
  });
});