define('panal-fresh-admin/utils/measure-util', ['exports', 'moment'], function (exports, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.retrieveArrayOfStatusClientPrices = exports.retrieveArrayOfStatus = undefined;


  const retrieveChangesOfObjects = (obj1, obj2) => {
    let newObj = {};
    for (let key in obj1) {
      if (obj1[key] !== obj2[key]) newObj[key] = obj2[key];
    }
    return Object.keys(newObj).length ? newObj : false;
  };

  const retrieveArrayOfStatus = exports.retrieveArrayOfStatus = (originals, modifieds, updated_by) => {
    var statusOfMeasures = [];
    const userAndTimeStamp = {
      updated_by,
      updated_at: (0, _moment.default)()
    };
    var indexCache = [...Array(modifieds.length).keys()];

    originals.forEach(originalMeasure => {
      const index = modifieds.findIndex(modifiedMeasure => originalMeasure.uom_id === modifiedMeasure.uom_id && originalMeasure.uom_quantity === modifiedMeasure.uom_quantity);

      let statusMeasure = {
        ids: {
          uom_quantity: originalMeasure.uom_quantity,
          uom_id: originalMeasure.uom_id
        }
      };

      if (index === -1) statusOfMeasures.push(Object.assign(statusMeasure, { deleted: true }));else {
        let updated = retrieveChangesOfObjects(originalMeasure, modifieds[index]);
        delete indexCache[index];

        if (updated) {
          updated = Object.assign(updated, userAndTimeStamp);
          statusOfMeasures.push(Object.assign(statusMeasure, { updated }));
        }
      }
    });

    indexCache.forEach(i => {
      if (i > -1) {
        const modified = modifieds[i];
        const insertMeasure = {
          insert: Object.assign(modified, userAndTimeStamp)
        };
        statusOfMeasures.push(insertMeasure);
      }
    });

    return statusOfMeasures;
  };

  const retrieveArrayOfStatusClientPrices = exports.retrieveArrayOfStatusClientPrices = (originals, modifieds, updated_by) => {
    return {
      status: retrieveArrayOfStatus(originals, modifieds, updated_by),
      isMeasuresWithStates: true
    };
  };
});