define('panal-fresh-admin/routes/order/detail', ['exports', 'panal-fresh-admin/config/environment', 'panal-fresh-admin/mixins/protected-route-mixin'], function (exports, _environment, _protectedRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_protectedRouteMixin.default, {
    orderService: Ember.inject.service('order'),

    model: function (queryParams) {
      return this.get('orderService').findById(queryParams.order_id);
    },

    setupController: function (controller, model, transition) {
      this._super(controller, model, transition);

      controller.set('errors', []);
      controller._reset();
      controller._loadStatus(model);
      controller.set('previousDeliveryCost', model.delivery_cost);

      controller.set('status', controller.get('statusList').find(item => {
        return item.id == model.status.id;
      }));
      controller.set('title', `Detalle de la orden: ${model.order_id} (${model.status.display_name})`);
    }
  });
});