define('panal-fresh-admin/controllers/shopping-list/purchase', ['exports', 'panal-fresh-admin/mixins/pagination-controller-mixin', 'ember-sweetalert'], function (exports, _paginationControllerMixin, _emberSweetalert) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend(_paginationControllerMixin.default, {
    shoppingListService: Ember.inject.service('shopping-list'),

    actions: {
      refreshModel: function () {
        Ember.getOwner(this).lookup('route:shopping-list.purchase').refresh();
      }
    }
  });
});