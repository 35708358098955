define('panal-fresh-admin/mixins/pagination-route-mixin', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    queryParams: {
      perPage: {
        refreshModel: true
      },
      page: {
        refreshModel: true
      }
    },
    perPage: 25,
    startingPage: 1,

    findPaged: function (findAllOptions, params = {}) {
      let queryParams = findAllOptions.data || {};
      let headers = findAllOptions.headers || {};

      let page = params.page || this.get('startingPage');
      let perPage = params.perPage || this.get('perPage');

      var paginationParams = {
        offset: (page - 1) * perPage,
        limit: perPage
      };

      findAllOptions.data = Object.assign(queryParams, paginationParams);
      findAllOptions.headers = Object.assign(headers, {
        'X-Paginated': true,
        'Authorization': localStorage.getItem('userUID')
      });

      let region = localStorage.getItem('region');
      if (region) {
        let savedRegion = JSON.parse(region);

        if (savedRegion && savedRegion.schema) {
          findAllOptions.headers['X-Region'] = savedRegion.schema;
        }
      }

      let self = this;

      return Ember.$.ajax(findAllOptions).then(function (data, status, request) {
        let totalPages = request.getResponseHeader('X-Total-Pages') || 1;
        self.set('totalPages', totalPages);

        return data;
      });
    },

    setupController: function (controller, model, transition) {
      this._super(controller, model, transition);

      controller.set('totalPages', this.get('totalPages'));
      controller.updatePagination();
    }
  });
});