define('panal-fresh-admin/controllers/modals/shopping-list-item', ['exports', 'ember-sweetalert'], function (exports, _emberSweetalert) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    shoppingListService: Ember.inject.service('shopping-list'),

    extraButtons: Array(),

    initController: function (groups, itemId) {
      groups = Array.from(groups);
      groups.forEach(group => {
        group.display = `${group.product.name}`;

        if (group.unified) {
          group.display = `${group.display} - Unificado - ${group.uom_display}`;
        } else {
          group.display = `${group.display} - Separado`;
        }
      });

      this.set('groups', groups);

      let itemPromise = itemId ? this._loadItem(itemId) : {
        shopping_list_group_id: null,
        order_id: null,
        quantity: 1
      };

      Promise.resolve(itemPromise).then(item => {
        this.set('item', item);

        if (item.shopping_list_item_id) {
          this.set('title', `Editar Item - ${item.product.name}`);

          let group = groups.find(group => {
            return group.shopping_list_group_id == item.shopping_list_group_id;
          });

          this.set('originalGroup', group);
          this.set('group', group || groups[0]);

          this.set('extraButtons', [{
            action: 'removeItem',
            name: 'Remover',
            class: 'btn btn-danger'
          }]);
        } else {
          this.set('title', 'Crear Item');
          this.set('extraButtons', []);
          this.set('originalGroup', null);
          this.set('group', groups[0]);
        }
      });

      return this;
    },

    done: function saveCallBack(saveCallback) {
      this.set('saveCallback', saveCallback);
    },

    actions: {
      submit: function () {
        let item = this.get('item');
        let group = this.get('group');
        let itemToSave = {
          shopping_list_group_id: group.shopping_list_group_id
        };

        let promise;

        if (item.shopping_list_item_id) {
          promise = this.get('shoppingListService').updateItem(item.shopping_list_item_id, itemToSave);
        } else {
          promise = this.get('shoppingListService').saveItem(itemToSave);
        }

        return promise.then(() => {
          this.get('saveCallback')();
          jQuery('.modal').modal('hide');
        }).catch(error => {
          (0, _emberSweetalert.default)({
            title: "Error",
            type: "error",
            text: error.responseJSON.message,
            showConfirmButton: true
          }).then(() => {});
        });
      },

      extra: function (action) {
        if (action == 'removeItem') {
          (0, _emberSweetalert.default)({
            title: '¿Está seguro que desea eliminar el item?',
            text: "No podras revertir este proceso.",
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Si, eliminalo!'
          }).then(result => {
            if (result) {
              this._removeItem();
            }
          });
        }
      }
    },

    _removeItem() {
      let item = this.get('item');

      return this.get('shoppingListService').removeItem(item.shopping_list_item_id).then(() => {
        this.get('saveCallback')();
        jQuery('.modal').modal('hide');
        (0, _emberSweetalert.default)('Eliminado!', 'El item ha sido eliminado.', 'success');
      }).catch(error => {
        (0, _emberSweetalert.default)({
          title: "Error",
          type: "error",
          text: "El item no puede ser eliminado.",
          showConfirmButton: true
        }).then(() => {});
      });
    },

    _loadItem: function (itemId) {
      return this.get('shoppingListService').itemById(itemId);
    }
  });
});