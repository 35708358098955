define('panal-fresh-admin/controllers/modals/view-providers', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({

    initController: function (providers = null) {
      this.set('providers', providers);
      return this;
    },

    done: function saveCallBack(saveCallback) {
      this.set('saveCallback', saveCallback);
      this.set('title', 'Lista de proveedores');
    }

  });
});