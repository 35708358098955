define('panal-fresh-admin/routes/report/index', ['exports', 'panal-fresh-admin/mixins/pagination-route-mixin', 'panal-fresh-admin/mixins/protected-route-mixin'], function (exports, _paginationRouteMixin, _protectedRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_protectedRouteMixin.default, _paginationRouteMixin.default, {
    setupController: function (controller, model, transition) {
      this._super(controller, model);

      controller.set('errors', []);
    },
    model() {
      return [['Data', 'data']];
    }
  });
});