define('panal-fresh-admin/controllers/report/index', ['exports', 'panal-fresh-admin/mixins/pagination-controller-mixin', 'ember-sweetalert'], function (exports, _paginationControllerMixin, _emberSweetalert) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend(_paginationControllerMixin.default, {
    queryParams: ['status'],
    report: Ember.inject.service('report'),
    ExcelService: Ember.inject.service('order'),
    groups: Array(),
    items: [],
    filterListOptions: Array({
      id: 'ACTIVE',
      display: 'Activos'
    }, {
      id: 'CONFIRMED',
      display: 'Confirmados'
    }, {
      id: 'WAITING_CONFIRMATION',
      display: 'En espera de pago'
    }, {
      id: 'DELIVERED',
      display: 'Pagados'
    }, {
      id: 'CANCELED',
      display: 'Cancelados'
    }, {
      id: 'ARCHIVED',
      display: 'Archivados'
    }),
    filterOption: {
      id: 'CONFIRMED',
      display: 'Confirmados'
    },
    filterReport: Array({
      id: 1,
      display: "Ordenes"
    }, {
      id: 2,
      display: "Rango de fechas"
    }, {
      id: 3,
      display: "Estado de ordenes"
    }, {
      id: 4,
      display: "Fechas y estado"
    }, {
      id: 5,
      display: "Fechas y comprador"
    }),
    reportFilter: {
      id: 1,
      display: "ordenes"
    },
    actions: {
      addOrder(order) {
        if (parseInt(order)) this.get('items').pushObject(parseInt(order));
      },
      removeOrder(index) {
        this.get('items').removeAt(index);
      },
      onDateSelectedIni: function (newDate) {
        this.set('dateIni', newDate);
      },
      onDateSelectedEnd: function (newDate) {
        this.set('dateEnd', newDate);
      },
      downloadExcel: function () {
        let payload = {};
        switch (this.get('reportFilter.id')) {
          case 1:
            payload = {
              orders: this.get('items')
            };
            break;
          case 2:
            payload = {
              between: [this.get('dateIni'), this.get('dateEnd')]
            };
            break;
          case 3:
            payload = {
              status: this.get('filterOption.id')
            };
            break;
          case 4:
            payload = {
              between: [this.get('dateIni'), this.get('dateEnd')],
              status: this.get('filterOption.id')
            };
            break;
          case 5:
            payload = {
              between: [this.get('dateIni'), this.get('dateEnd')],
              updated_by: this.get('name')
            };
            break;
        }
        this.ExcelService.downloadExcel(payload).then(() => {
          (0, _emberSweetalert.default)({
            title: "Exito",
            type: "success",
            text: "Descargando",
            showConfirmButton: true
          }).then(() => {
            Ember.getOwner(this).lookup('route:report').refresh();
          });
        }).catch(error => {
          (0, _emberSweetalert.default)({
            title: "Error",
            type: "error",
            text: "No existen ordenes",
            showConfirmButton: true
          }).then(() => {});
        });
      }
    }
  });
});