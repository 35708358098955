define('panal-fresh-admin/controllers/modals/create-provider', ['exports', 'ember-sweetalert'], function (exports, _emberSweetalert) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    providerService: Ember.inject.service('provider'),
    options: {
      theme: "snow",
      modules: {
        toolbar: [[{ header: [2, 3, 4, false] }], ["bold", "italic", "underline", "strike"], [{ "color": [] }], [{ "list": "ordered" }, { "list": "bullet" }], [{ "indent": "-1" }, { "indent": "+1" }], [{ "align": [] }], ["link"], ["clean"]]
      }
    },

    initController: function (providerId = null) {
      if (providerId) {
        this._loadProvider(providerId).then(provider => {
          this.set('provider', provider);
          this.set('title', 'Editar Proveedor');
          if (!provider.philosophy) {
            console.log('entra');
            this.set('provider.philosophy', '<p> </p>');
          }
        });
      } else {
        this.set('provider', {
          name: '',
          address: '',
          cell_phone_number: '',
          description: '',
          is_hidden: 1,
          has_suscription: false
        });
        this.set('title', 'Crear Proveedor');
      }
      return this;
    },

    done: function saveCallBack(saveCallback) {
      this.set('saveCallback', saveCallback);
    },

    actions: {
      submit: function () {
        if (this.get('provider.name') == '' || this.get('provider.address') == '') {
          (0, _emberSweetalert.default)({
            title: "Error",
            type: "error",
            text: "Ingrese todos los campos requeridos.",
            showConfirmButton: true
          }).then(() => {});
        } else {
          this._saveProvider();
        }
      },
      updateText: function (editor) {
        console.log(editor.root.innerHTML);
        console.log(this.get('provider.philosophy'));
        this.set('provider.philosophy', editor.root.innerHTML);
      }
    },

    _loadProvider: function (providerId) {
      return this.get('providerService').findById(providerId);
    },

    _saveProvider(newImageURL) {
      let providerToSave = Object.assign({}, this.get('provider'));
      providerToSave.is_hidden = 0;
      let promise = null;
      if (providerToSave.provider_id) {
        const providerId = providerToSave.provider_id;
        delete providerToSave.provider_id;
        promise = this.get('providerService').update(providerId, providerToSave);
      } else {
        promise = this.get('providerService').save(providerToSave);
      }

      return promise.then(() => {
        this.get('saveCallback')();
        jQuery('.modal').modal('hide');
      }).catch(error => {
        (0, _emberSweetalert.default)({
          title: "Error",
          type: "error",
          text: error.responseJSON.message,
          showConfirmButton: true
        }).then(() => {});
      });
    }
  });
});