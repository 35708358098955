define('panal-fresh-admin/controllers/product/index', ['exports', 'panal-fresh-admin/mixins/pagination-controller-mixin', 'ember-sweetalert'], function (exports, _paginationControllerMixin, _emberSweetalert) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend(_paginationControllerMixin.default, {
    productService: Ember.inject.service('product'),

    queryParams: ['query', 'enabled'],
    query: '',
    enabled: true,

    createProductController: Ember.inject.controller('modals/create-product'),
    viewProductController: Ember.inject.controller('modals/view-product-details'),
    updateClientPricesController: Ember.inject.controller('modals/update-client-prices'),

    onQueryUpdated: Ember.observer('query', function () {
      Ember.run.debounce(this, this.applyQuery, 1000);
    }),

    onEnabledUpdated: Ember.observer('enabled', function () {
      this.applyQuery();
    }),

    applyQuery: function () {
      this.send('resetPagination');
    },

    actions: {
      refreshModel: function () {
        Ember.getOwner(this).lookup('route:product.index').refresh();
      },

      createProduct: function () {
        var self = this;
        this.send('showModal', 'modals/create-product');
        this.get('createProductController').initController().done(function () {
          self.send('refreshModel');
        });
      },

      savePromotion: function (product) {
        return this.get('productService').update(product.product_id, { 'has_promotion': !product.has_promotion }).then(() => {
          this.send('refreshModel');
          (0, _emberSweetalert.default)('Actualizado!', 'La promoción fue actualizada', 'success');
        }).catch(error => {
          (0, _emberSweetalert.default)({
            title: "Error",
            type: "error",
            text: "El producto no puede ser actualizado.",
            showConfirmButton: true
          }).then(() => {});
        });
      },

      editProduct: function (productId) {
        var self = this;
        this.send('showModal', 'modals/create-product');
        this.get('createProductController').initController(productId).done(function () {
          self.send('refreshModel');
        });
      },

      viewProduct: function (productId) {
        var self = this;
        this.send('showModal', 'modals/view-product-details');
        this.get('viewProductController').initController(productId).done(function () {
          self.send('refreshModel');
        });
      },

      updateClientPrices: function (productId) {
        var self = this;
        this.send('showModal', 'modals/update-client-prices');
        this.get('updateClientPricesController').initController(productId).done(function () {
          self.send('refreshModel');
        });
      },

      deleteProduct: function (productId) {
        var self = this;

        (0, _emberSweetalert.default)({
          title: '¿Está seguro que desea eliminar el producto?',
          text: "No podras revertir este proceso.",
          type: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Si, eliminalo!'
        }).then(result => {
          if (result) {
            self._deleteProduct(productId);
          }
        });
      }
    },

    _deleteProduct: function (productId) {
      return this.get('productService').remove(productId).then(() => {
        this.send('refreshModel');
        (0, _emberSweetalert.default)('Eliminado!', 'El producto ha sido eliminado.', 'success');
      }).catch(error => {
        (0, _emberSweetalert.default)({
          title: "Error",
          type: "error",
          text: "El producto no puede ser eliminado porque tiene unidades de media, ordenes o carritos relacionados con este producto.",
          showConfirmButton: true
        }).then(() => {});
      });
    }
  });
});