define('panal-fresh-admin/controllers/modals/create-shopping-list', ['exports', 'ember-sweetalert', 'moment'], function (exports, _emberSweetalert, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    userService: Ember.inject.service('user'),
    shoppingListService: Ember.inject.service('shopping-list'),

    initController: function () {
      let today = new _moment.default();

      this.set('date', new Date());
      this.set('minDate', new Date());

      this._loadShoppers();

      return this;
    },

    done: function saveCallBack(saveCallback) {
      this.set('saveCallback', saveCallback);
    },

    actions: {
      submit: function () {
        let payload = {
          date: this.get('date'),
          user_responsible_uid: this.get('shopper').uid
        };

        return this.get('shoppingListService').save(payload).then(employee => {
          this.get('saveCallback')();
          jQuery('.modal').modal('hide');
        }).catch(error => {
          (0, _emberSweetalert.default)({
            title: "Error",
            type: "error",
            text: error.responseJSON.message,
            showConfirmButton: true
          }).then(() => {});
        });
      },

      onDateSelected: function (newDate) {
        this.set('date', newDate);
      }
    },

    _loadShoppers: function () {
      return this.get('userService').shoppers().then(shoppers => {
        this.set('shoppers', shoppers);
        this.set('shopper', this.get('shoppers')[0] || null);

        return shoppers;
      });
    }
  });
});