define('panal-fresh-admin/routes/shopping-list/edit', ['exports', 'panal-fresh-admin/config/environment', 'panal-fresh-admin/mixins/protected-route-mixin'], function (exports, _environment, _protectedRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_protectedRouteMixin.default, {
    shoppingListService: Ember.inject.service('shopping-list'),

    model: function (queryParams) {
      return this.get('shoppingListService').findById(queryParams.shopping_list_id);
    },

    setupController: function (controller, model, transition) {
      this._super(controller, model, transition);

      controller.set('errors', []);

      let statusList = controller.get('statusList');
      let status = statusList.find(status => {
        return model.status == status.id;
      });
      controller.set('status', status);

      controller.updateGroupRowPositions();
    }
  });
});