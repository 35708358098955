define('panal-fresh-admin/controllers/shopping-list/index', ['exports', 'panal-fresh-admin/mixins/pagination-controller-mixin', 'ember-sweetalert'], function (exports, _paginationControllerMixin, _emberSweetalert) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend(_paginationControllerMixin.default, {
    queryParams: ['status'],

    groups: Array(),
    filterList: Array({
      id: 'DESIGNING',
      display: 'En diseño'
    }, {
      id: 'ACTIVE',
      display: 'En compra'
    }, {
      id: 'PURCHASED',
      display: 'Comprado'
    }, {
      id: 'CANCELED',
      display: 'Cancelado'
    }),

    filter: {
      id: 'DESIGNING',
      display: 'En diseño'
    },

    status: 'DESIGNING',

    onProductSelected: Ember.observer('filter', function () {
      this.set('status', this.get('filter.id'));
    }),

    createShoppingListController: Ember.inject.controller('modals/create-shopping-list'),

    actions: {
      refreshModel: function () {
        Ember.getOwner(this).lookup('route:shopping-list.index').refresh();
      },

      createShoppingList: function () {
        var self = this;
        this.send('showModal', 'modals/create-shopping-list');
        this.get('createShoppingListController').initController().done(function () {
          self.send('refreshModel');
        });
      },

      editShoppingList: function () {}
    }
  });
});