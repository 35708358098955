define('panal-fresh-admin/controllers/season/index', ['exports', 'panal-fresh-admin/mixins/pagination-controller-mixin', 'firebase'], function (exports, _paginationControllerMixin, _firebase) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend(_paginationControllerMixin.default, {
    viewSeasonController: Ember.inject.controller('modals/view-season-details'),
    queryParams: ['status'],
    report: Ember.inject.service('report'),
    groups: Array(),
    data: 'panal',
    actions: {

      updateImage: function (position, file) {
        if (position) {
          let image = _firebase.default.storeage.ref('/season/seasonLeft');
          image.put(file);
        } else {
          let image = _firebase.default.storeage.ref('/season/seasonRight');
          image.put(file);
        }
      },
      viewSeasonImage: function (position) {
        this.send('showModal', 'modals/view-season-details');
        this.get('viewSeasonController').initController(position);
      } }
  });
});