define('panal-fresh-admin/controllers/modals/view-step-details', ['exports', 'ember-sweetalert'], function (exports, _emberSweetalert) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({

    stepService: Ember.inject.service('step'),
    firebaseApp: Ember.inject.service(),

    initController: function (stepId = null, recipeId = null) {
      this._reset();

      this._loadStep(recipeId, stepId).then(step => {
        this.set('step', step);
        this.set('currentImagePath', step.image);
        this.set('title', step.step);
      });
      return this;
    },

    done: function saveCallBack(saveCallback) {
      this.set('saveCallback', saveCallback);
    },

    actions: {
      submit: function () {
        let imageFile = this.get('currentImageFile');

        if (!imageFile) {
          return;
        }

        const storage = this.get('firebaseApp').storage();

        let metadata = {
          'contentType': imageFile.type
        };

        let stepId = this.get('step.step');
        let recipeId = this.get('step.recipe_id');
        let fileExtension = imageFile.name.split('.').pop();

        let regionPath = '';
        let region = localStorage.getItem('region');
        if (region) {
          let savedRegion = JSON.parse(region);

          if (savedRegion && savedRegion.schema && savedRegion.schema != 'cochabamba') {
            regionPath = `${savedRegion.schema}/`;
          }
        }

        let stepImagePath = `images/step/${regionPath}${recipeId}${stepId}.${fileExtension}`;

        let storageRef = storage.ref();
        let uploadImageRef = storageRef.child(stepImagePath);
        var uploadTask = uploadImageRef.put(imageFile, metadata);

        uploadTask.on('state_changed', snapshot => {
          var progress = snapshot.bytesTransferred / snapshot.totalBytes * 100;
          this.set('progress', progress);
          switch (snapshot.state) {
            case 'paused':
              this.set('status', 'Upload is paused');
              break;
            case 'running':
              this.set('status', 'Upload is running');
              break;
          }
        }, error => {
          let message = '';
          switch (error.code) {
            case 'storage/unauthorized':
              message = 'El usuario no tiene permiso para subir imagenes.';
              break;
            case 'storage/canceled':
              message = 'El proceso fue cancelado.';
              break;
            case 'storage/unknown':
              message = 'El proceso fallo.';
              break;
          }

          (0, _emberSweetalert.default)({
            title: "Error",
            type: "error",
            text: message,
            showConfirmButton: true
          }).then(() => {});
        }, () => {
          this._updateStepImage(uploadTask.snapshot.downloadURL).then(() => {
            this.get('saveCallback')();
            jQuery('.modal').modal('hide');
          });
        });
      },

      didSelectFile: function (fileList) {
        if (fileList.length == 0) {
          return;
        }

        let imageFile = fileList[0];

        var imageTempPath = URL.createObjectURL(imageFile);
        this.set('currentImagePath', imageTempPath);
        this.set('currentImageFile', imageFile);
      }
    },

    _loadStep: function (recipeId, stepId) {
      return this.get('stepService').findById(recipeId, stepId);
    },

    _updateStepImage: function (newImageURL) {
      let stepId = this.get('step.step');
      let recipeId = this.get('step.recipe_id');

      return this.get('stepService').update(recipeId, stepId, {
        image: newImageURL
      });
    },

    _reset: function () {
      this.set('currentImageFile', null);
      this.set('progress', 0);
    }
  });
});