define('panal-fresh-admin/services/client', ['exports', 'panal-fresh-admin/mixins/api-service-mixin'], function (exports, _apiServiceMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend(_apiServiceMixin.default, {
    init: function () {
      this._super(...arguments);
    },

    all: function () {
      return this.getRequest('/v1/client');
    },

    findById: function (clientUId) {
      return this.getRequest(`/v1/client/${clientUId}`);
    },

    save: function (payload) {
      return this.post('/v1/client', payload);
    },

    update: function (clientUId, payload) {
      return this.patch(`/v1/client/${clientUId}`, payload);
    },

    productPrices: function (clientUID, productId) {
      return this.getRequest(`/v1/client/${clientUID}/product/${productId}`);
    },

    updateProductPrice: function (clientUID, productId, payload) {
      return this.post(`/v1/client/${clientUID}/product/${productId}/price`, payload);
    },

    remove: function (clientUId) {
      return this.delete(`/v1/client/${clientUId}`);
    }
  });
});