define('panal-fresh-admin/controllers/modals/view-season-details', ['exports', 'ember-sweetalert'], function (exports, _emberSweetalert) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({

    firebaseApp: Ember.inject.service(),

    initController: function (position = null) {
      this._reset();
      if (position) {
        this.set('position', position);
        this.set('currentImagePath', 'https://firebasestorage.googleapis.com/v0/b/panal-fresh.appspot.com/o/season%2FseasonImageLeft.jpg?alt=media&token=339c907f-8a3b-4ea2-9b2b-eb132c59de03');
      } else {
        this.set('position', position);
        this.set('currentImagePath', 'https://firebasestorage.googleapis.com/v0/b/panal-fresh.appspot.com/o/season%2FseasonImageRight.jpg?alt=media&token=b885a306-4a7e-4c83-9cc8-121d0c007e7c');
      }
    },

    done: function saveCallBack(saveCallback) {
      this.set('saveCallback', saveCallback);
    },

    actions: {
      submit: function () {
        let imageFile = this.get('currentImageFile');

        if (!imageFile) {
          return;
        }

        const storage = this.get('firebaseApp').storage();

        let metadata = {
          'contentType': imageFile.type
        };

        let fileExtension = imageFile.name.split('.').pop();
        let seasonImagePath = '';
        if (this.get('position') == true) {
          seasonImagePath = `season/seasonImageLeft.jpg`;
        } else {
          seasonImagePath = `season/seasonImageRight.jpg`;
        }
        let storageRef = storage.ref();
        let uploadImageRef = storageRef.child(seasonImagePath);
        var uploadTask = uploadImageRef.put(imageFile, metadata);

        uploadTask.on('state_changed', snapshot => {
          var progress = snapshot.bytesTransferred / snapshot.totalBytes * 100;
          this.set('progress', progress);
          switch (snapshot.state) {
            case 'paused':
              this.set('status', 'Upload is paused');
              break;
            case 'running':
              this.set('status', 'Upload is running');
              break;
          }
        }, error => {
          let message = '';
          switch (error.code) {
            case 'storage/unauthorized':
              message = 'El usuario no tiene permiso para subir imagenes.';
              break;
            case 'storage/canceled':
              message = 'El proceso fue cancelado.';
              break;
            case 'storage/unknown':
              message = 'El proceso fallo.';
              break;
          }

          (0, _emberSweetalert.default)({
            title: "Error",
            type: "error",
            text: message,
            showConfirmButton: true
          }).then(() => {});
        });
      },

      didSelectFile: function (fileList) {
        if (fileList.length == 0) {
          return;
        }

        let imageFile = fileList[0];

        var imageTempPath = URL.createObjectURL(imageFile);
        this.set('currentImagePath', imageTempPath);
        this.set('currentImageFile', imageFile);
      }
    },

    _reset: function () {
      this.set('currentImageFile', null);
      this.set('progress', 0);
    }
  });
});