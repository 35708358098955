define('panal-fresh-admin/services/panal', ['exports', 'panal-fresh-admin/mixins/api-service-mixin'], function (exports, _apiServiceMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend(_apiServiceMixin.default, {
    init: function () {
      this._super(...arguments);
    },

    all: function () {
      return this.getRequest(`/v1/user/panal`);
    },

    findById: function (panalId) {
      return this.getRequest(`/v1/panal/${panalId}`);
    },

    save: function (payload) {
      return this.post('/v1/panal', payload);
    },

    update: function (panalId, payload) {
      return this.patch(`/v1/panal/${panalId}`, payload);
    },

    remove: function (panalId) {
      return this.delete(`/v1/panal/${panalId}`);
    }
  });
});