define('panal-fresh-admin/controllers/assign-order/index', ['exports', 'panal-fresh-admin/mixins/pagination-controller-mixin', 'ember-sweetalert'], function (exports, _paginationControllerMixin, _emberSweetalert) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend(_paginationControllerMixin.default, {
    queryParams: ['query', 'city'],
    query: 'CONFIRMED',
    city: 'CBB',
    orderService: Ember.inject.service('order'),
    userService: Ember.inject.service('user'),

    cityChange: Ember.observer('citySelected', function () {
      this._loadCity();
      this._getAllOrder();
    }),

    actions: {
      refreshModel: function () {
        Ember.getOwner(this).lookup('route:order.index').refresh();
      },

      assignOrder: function (event) {
        console.log(this.fullOrder.assignee_uid === this.user.uid);
        if (this.get('orderItems')) {

          let self = this;
          let orderSelected = this.get('modelOrder').find(item => item.order_id == this.get('orderId'));
          if (orderSelected.assignee_uid != null && orderSelected.assignee_uid != this.get('user.uid')) {
            let userAssigned = this.get('modelUsers').find(item => item.uid == orderSelected.assignee_uid);
            (0, _emberSweetalert.default)({
              title: "Alto",
              text: `¿La orden ${orderSelected.order_id} esta asignanda a ${userAssigned.displayName}, desea reasignar a otro usuario?`,
              type: 'warning',
              showCancelButton: true,
              confirmButtonColor: '#3085d6',
              cancelButtonColor: '#d33',
              confirmButtonText: 'Si, reasignar!'
            }).then(result => {
              if (result) {
                self._updateOrder(self.get('orderId'), { 'assignee_uid': self.get('user.uid') }).then(order => {
                  self.set('fullOrder.assignee_uid', self.get('user.uid'));
                  self._getAllOrder();
                  self._getAllOrdertoUser(self.get('user.uid'));
                  (0, _emberSweetalert.default)({
                    title: "exito",
                    type: "success",
                    text: `La orden ${self.get('orderId')} fue asignada`,
                    showConfirmButton: true
                  });
                });
              }
            });
          } else {
            if (this.fullOrder.assignee_uid === this.user.uid) {
              (0, _emberSweetalert.default)({
                title: "Alto",
                text: `¿Estas seguro que quieres desasignar esta orden de este usuario?`,
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Si, reasignar!'
              }).then(result => {
                if (result) {
                  self._updateOrder(self.get('orderId'), { 'assignee_uid': null }).then(order => {
                    self.set('fullOrder.assignee_uid', null);
                    self._getAllOrder();
                    self._getAllOrdertoUser(self.get('user.uid'));
                    (0, _emberSweetalert.default)({
                      title: "exito",
                      type: "success",
                      text: `La orden ${self.get('orderId')} fue desasignada`,
                      showConfirmButton: true
                    });
                  });
                }
              });
            } else {
              self._updateOrder(self.get('orderId'), { 'assignee_uid': self.get('user.uid') }).then(order => {
                self.set('fullOrder.assignee_uid', self.get('user.uid'));
                self._getAllOrder();
                self._getAllOrdertoUser(self.get('user.uid'));
                (0, _emberSweetalert.default)({
                  title: "exito",
                  type: "success",
                  text: `La orden ${self.get('orderId')} fue asignada`,
                  showConfirmButton: true
                });
              });
            }
          }
        } else {
          (0, _emberSweetalert.default)({
            title: "Aviso",
            type: "info",
            text: "Seleccione una orden a asignar.",
            showConfirmButton: true
          });
        }
      },

      cancelAssignment: function (orderId) {
        let self = this;
        (0, _emberSweetalert.default)({
          title: "Alto",
          text: `¿Está seguro que desea cancelar la orden ${orderId} de la asignación?`,
          type: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Si, remuévalo!'
        }).then(result => {
          if (result) {
            self._updateOrder(orderId, { 'assignee_uid': null }).then(order => {
              this._getAllOrder();
              this._getAllOrdertoUser(this.get('user.uid'));
              (0, _emberSweetalert.default)({
                title: "exito",
                type: "success",
                text: `Orden ${orderId} fue removida`,
                showConfirmButton: true
              });
            });
          }
        });
      },

      onOrderClick: function (order) {
        this.set('fullOrder', order);
        this.set('orderId', order.order_id);
        this.set('orderItems', order.items);
      },

      onUserClick: function (user) {
        this.set('user', user);
        this._getAllOrdertoUser(user.uid);
      }
    },

    _getAllOrder: function () {
      let queryParams = {
        'q': 'CONFIRMED',
        filters: `city:${this.get('citySelected.id')}`
      };
      return this.orderService.all(queryParams).then(orders => {
        this.set('modelOrder', orders);
      });
    },

    _getAllOrdertoUser: function (userUid) {
      let queryParams = {
        'filters[status]': 'CONFIRMED',
        'filters[city]': this.get('citySelected.id'),
        'filters[assignee_uid]': userUid
      };
      return this.orderService.all(queryParams).then(order => {
        this.set('user.orders', order);
      });
    },

    _updateOrder: function (orderId, payload) {
      return this.orderService.update(orderId, payload);
    },

    _loadCity: function () {
      this.userService.getShoppers(this.get('citySelected.id')).then(result => {
        this.set('modelUsers', result);
      });
    }
  });
});