define('panal-fresh-admin/controllers/order/index', ['exports', 'panal-fresh-admin/mixins/pagination-controller-mixin'], function (exports, _paginationControllerMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend(_paginationControllerMixin.default, {
    queryParams: ['filter', 'city'],
    filter: 'ACTIVE',
    city: 'CBB',
    activeCheck: true,
    ExcelService: Ember.inject.service('order'),
    filterCity: Array({
      id: "CBB",
      display: "Cochabamba"
    }, {
      id: "SCZ",
      display: "Santa Cruz"
    }, {
      id: "LPZ",
      display: "La Paz"
    }, {
      id: "EAL",
      display: "El Alto"
    }),
    citySelect: {
      id: "CBB",
      display: "Cochabamba"
    },
    printOrderController: Ember.inject.controller('modals/print-order'),
    printInvoiceController: Ember.inject.controller('modals/print-invoice'),
    addToShoppingListController: Ember.inject.controller('modals/add-to-shopping-list'),

    onQueryUpdated: Ember.observer('citySelect', function () {
      this.set('city', this.get('citySelect.id'));
    }),

    actions: {
      refreshModel: function () {
        Ember.getOwner(this).lookup('route:order.index').refresh();
      },

      printOrder: function (orderId) {
        var self = this;
        this.send('showModal', 'modals/print-order');
        this.get('printOrderController').initController(orderId).done(function () {
          self.send('refreshModel');
        });
      },

      printInvoice: function (orderId) {
        var self = this;
        this.send('showModal', 'modals/print-invoice');
        this.get('printInvoiceController').initController(orderId).done(function () {
          self.send('refreshModel');
        });
      },

      addToShoppingList: function (orderId) {
        this.send('showModal', 'modals/add-to-shopping-list');
        this.get('addToShoppingListController').initController(orderId).done(function () {});
      },

      downloadExcel: function () {
        this.ExcelService.downloadExcel();
      }

    }
  });
});